import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { Input, Modal } from "antd";
import clone from 'lodash/clone';
import differenceBy from 'lodash/differenceBy';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import sortedIndexBy from 'lodash/sortedIndexBy';
import { useEffect, useState } from "react";
import { AvatarText, UserAvatarGroup } from "~/components";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { Button, customScroll } from "~/styles";
const membersInputStyle = css({
    height: 40,
    display: "flex",
    borderRadius: 6,
    border: `1px solid ${theme["neutral-20"]}`,
    padding: "0px 10px",
    transition: "all .2s",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
        borderColor: theme["primary-80"],
        borderInlineEndWidth: 1
    },
    ".members-name": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    ".icon": {
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme["neutral-10"],
        borderRadius: "50%",
        marginRight: 6
    }
});
const modalStyle = css({
    ".ant-modal-content .ant-modal-close": {
        right: "unset",
        left: 24,
        ".ant-modal-close-x": {
            fontSize: 28,
        }
    },
    "&.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title": {
        paddingLeft: 64,
        paddingRight: theme.modalPadding
    },
    ".members-search .ant-input-clear-icon": {
        display: "inline-flex"
    },
    ".unselected-members, .selected-members": [{
            height: 432
        }, customScroll],
    ".selected-members": {
        borderRadius: 6,
        backgroundColor: theme["neutral-10"],
        border: `1px solid ${theme["neutral-20"]}`,
        "li": {
            borderBottom: `1px solid ${theme["neutral-20"]}`
        }
    },
    ".apply-button": {
        height: 40,
        width: 206,
        backgroundColor: theme["primary-100"],
        color: theme["neutral-0"],
        "&:disabled": {
            opacity: .6
        }
    }
});
const MembersSelectionItem = ({ member, icon, handler }) => {
    return (_jsxs("li", { className: 'd-flex align-center justify-space-between', style: { backgroundColor: theme["neutral-0"], padding: '12px 22px' }, children: [_jsx(AvatarText.User, { user: member, size: 48 }), _jsx(SvgIcon, { name: icon, size: 20, cursor: "pointer", color: theme["neutral-40"], onClick: () => handler(member) })] }, member.sub));
};
function toggleMember(membersSelection, operation, member) {
    const selectedMembers = clone(membersSelection.selectedMembers);
    const unselectedMembers = clone(membersSelection.unselectedMembers);
    const removeFrom = operation === "select" ? unselectedMembers : selectedMembers;
    const addTo = operation === "select" ? selectedMembers : unselectedMembers;
    remove(removeFrom, { sub: member.sub });
    addTo.splice(sortedIndexBy(addTo, member, 'name'), 0, member);
    return { selectedMembers, unselectedMembers };
}
export const MembersSelection = ({ members, value, onChange, belongsToTrip }) => {
    const selectedMembers = sortBy(value, 'name') || [];
    const unselectedMembers = sortBy(differenceBy(members, selectedMembers, 'sub'), 'name');
    const [membersSelection, setMembersSelection] = useState({ unselectedMembers, selectedMembers });
    const [filterValue, setFilterValue] = useState("");
    const selectMember = member => setMembersSelection(prev => toggleMember(prev, "select", member));
    const unSelectMember = member => setMembersSelection(prev => toggleMember(prev, "unselect", member));
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "d-flex mb-10", children: [_jsxs("div", { className: "flex-even mr-6", children: [_jsx(Input, { className: "members-search", placeholder: "Procurar", onChange: e => setFilterValue(e.target.value), prefix: _jsx(SvgIcon, { name: "ic_search", color: theme["neutral-50"], size: 18 }), allowClear: { clearIcon: _jsx(SvgIcon, { name: "ic_delete", size: 18 }) } }), _jsx("ul", { className: "pl-0 mt-3 mb-0 unselected-members", children: membersSelection.unselectedMembers
                                    .filter(m => m.name.toUpperCase().startsWith(filterValue.toUpperCase()))
                                    .map(m => _jsx(MembersSelectionItem, { member: m, icon: "ic_plus_circle", handler: selectMember }, m.sub)) })] }), _jsxs("div", { className: "flex-even", children: [_jsxs("p", { className: "type-body-4 mb-3", children: ["Membros ", belongsToTrip ? "da visita" : "do projeto"] }), _jsx("ul", { className: "pl-0 mt-3 mb-0 selected-members", children: membersSelection.selectedMembers.map(m => _jsx(MembersSelectionItem, { member: m, icon: "ic_less_circle", handler: unSelectMember }, m['sub'])) })] })] }), _jsx("div", { className: "d-flex justify-end py-5", children: _jsx(Button, { type: "primary", className: "apply-button", onClick: () => onChange(membersSelection.selectedMembers), children: "Aplicar" }) })] }));
};
export const MembersInput = ({ value, onChange: _onChange, members, onModalShow, belongsToTrip, id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isEmpty = value == undefined || value.length === 0;
    useEffect(() => onModalShow?.(isModalOpen), [isModalOpen, onModalShow]);
    const onChange = (selectedMembers) => {
        _onChange?.(selectedMembers);
        setIsModalOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: membersInputStyle, onClick: () => setIsModalOpen(true), id: id, children: [_jsx("div", { className: "d-flex align-center", style: { maxWidth: "calc(100% - 22px)" }, children: isEmpty ?
                            _jsxs(_Fragment, { children: [_jsx("div", { className: "icon", children: _jsx(SvgIcon, { name: "ic_add_user", size: 20, color: theme["neutral-50"] }) }), _jsx("span", { style: { color: theme["neutral-50"] }, children: "Adicionar membros" })] })
                            :
                                _jsxs(_Fragment, { children: [_jsx(UserAvatarGroup, { className: "mr-2", users: value }), _jsx("span", { className: "members-name", children: value.map(m => m['name']).join(", ") })] }) }), _jsx(SvgIcon, { name: "ic_right", size: 22, color: theme["neutral-50"] })] }), _jsx(Modal, { className: modalStyle, width: 944, centered: true, destroyOnClose: true, closeIcon: _jsx(SvgIcon, { name: "ic_back" }), open: isModalOpen, footer: null, title: "Membros", onCancel: () => setIsModalOpen(false), maskClosable: false, children: _jsx(MembersSelection, { value: value, onChange: onChange, members: members, belongsToTrip: belongsToTrip }) })] }));
};
