import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { css } from "@emotion/css";
import { Modal, message } from "antd";
import { theme } from "~/config";
import { Button } from "~/styles";
import { defaultModalProps } from "~/utils";
const style = css({
    ".url-wrapper": {
        padding: 10,
        borderRadius: 6,
        backgroundColor: theme["neutral-20"],
        userSelect: "all"
    }
});
export const ProjectShareModal = createModal(({ path }) => {
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const onCopyClick = () => {
        navigator.clipboard.writeText(`${window.location.origin}${path}`);
        message.success('Link copiado');
    };
    return (_jsxs(Modal, { ...defaultProps, className: style, title: "Partilhar", width: 484, footer: _jsx(Button, { type: "link", className: "pl-0", color: "@primary-100", onClick: onCopyClick, children: "Copiar link" }), children: [_jsx("p", { className: "my-0 type-body-1", children: "Partilhar projeto" }), _jsx("p", { className: "mt-3 mb-0 type-body-1 url-wrapper", children: `${window.location.origin}${path}` })] }));
});
