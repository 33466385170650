import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { App, Col, Form, Input, Modal, Row, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import pick from 'lodash/pick';
import { useState } from "react";
import { MapPicker } from "~/components/map-picker/map";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { ProjectManagementService } from "~/services";
import { Button, FormItem } from "~/styles";
import { defaultModalProps, formRules, globalNotificationConfig, useValidateForm } from "~/utils";
import style from "./style";
export const TripPointEditModal = createModal(({ project, tripPoint, catchExpection }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const { notification } = App.useApp();
    const modal = useModal();
    const [form] = useForm();
    const [isFormValid] = useValidateForm(form);
    const editMode = !!tripPoint;
    const initialValues = editMode &&
        {
            ...pick(tripPoint, ["name"]),
            coordinates: `${tripPoint["latitude"]},${tripPoint["longitude"]}`
        };
    const confirmationModal = useModal("confirmation-modal");
    const onCancelClick = () => {
        confirmationModal.show({
            sentence: "Tem a certeza que quer descartar as alterações?",
            cancelLabel: 'Não', confirmationLabel: 'Sim'
        }).then(() => {
            if (catchExpection)
                modal.reject();
            modal.hide();
        });
    };
    const defaultProps = defaultModalProps(modal);
    const modalProps = {
        ...defaultProps,
        open: modal.visible,
        title: editMode ? "Editar ponto de visita" : "Criar ponto de visita",
        closeIcon: _jsx(SvgIcon, { name: "ic_close", size: 28, color: theme["neutral-100"] }),
        onCancel: onCancelClick,
        footer: (_jsxs(Space, { size: 24, children: [_jsx(Button, { onClick: onCancelClick, disabled: isLoading, children: "Cancelar" }), _jsx(Button, { type: "primary", htmlType: "submit", disabled: !isFormValid || isLoading, loading: isLoading, form: "trip-point-form", children: editMode ? "Guardar alterações" : "Criar" })] }))
    };
    const onFinish = (formValues) => {
        setIsLoading(true);
        const data = {
            name: formValues['name'],
            latitude: formValues.coordinates.split(",")[0],
            longitude: formValues.coordinates.split(",")[1],
        };
        if (editMode) {
            let touchedFields = Object.keys(formValues).filter(form.isFieldTouched);
            if (touchedFields.includes("coordinates")) {
                touchedFields = touchedFields.filter(t => t !== 'coordinates');
                touchedFields.push("latitude", "longitude");
            }
            const body = pick(data, touchedFields);
            ProjectManagementService.updateTripPoint(body, tripPoint.id)
                .then(() => notification.success({ message: "As suas alterações foram salvas.", ...globalNotificationConfig }))
                .then(defaultProps.onOk)
                .catch(() => notification.error({ message: "Erro a atualizar o ponto de visita.", ...globalNotificationConfig }))
                .finally(() => setIsLoading(false));
        }
        else {
            data["project_id"] = project.id;
            ProjectManagementService.createTripPoint(data)
                .then(() => notification.success({ message: "Ponto de visita criado com sucesso.", ...globalNotificationConfig }))
                .then(defaultProps.onOk)
                .catch(() => { notification.error({ message: "Erro a criar o ponto de visita.", ...globalNotificationConfig }); defaultProps.onOk(); })
                .finally(() => setIsLoading(false));
        }
    };
    return (_jsxs(Modal, { width: 944, className: style(showMap), ...modalProps, children: [_jsx("div", { className: "type-body-4 mb-7", style: { color: theme["neutral-50"] }, children: "* Campo de preenchimento obrigat\u00F3rio" }), _jsx(Form, { id: "trip-point-form", form: form, layout: "vertical", initialValues: initialValues, preserve: false, onFinish: onFinish, children: _jsxs(Row, { gutter: 24, children: [_jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Nome", name: "name", rules: [formRules.required], children: _jsx(Input, { placeholder: "Adicionar nome do ponto de visita", maxLength: 140 }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Geolocaliza\u00E7\u00E3o", name: "coordinates", rules: [formRules.required], children: _jsx(MapPicker, { onModalShow: value => setShowMap(value) }) }) })] }) })] }));
});
