import { some } from "lodash-es";
export const theme = {
    // Typography
    'font-family': "Roboto",
    'base-font-size': 16,
    'base-line-height': 22,
    'font-weight-thin': 100,
    'font-weight-light': 300,
    'font-weight-regular': 400,
    'font-weight-medium': 500,
    'font-weight-bold': 700,
    'font-weight-black': 900,
    // Colors/Primary
    'primary-6': "#1890FF",
    'primary-10': "#F8FAFF",
    'primary-20': "#F1F6FF",
    'primary-40': "#E6EFFE",
    'primary-60': "#DAE7FF",
    'primary-80': "#3083FF",
    'primary-100': "#0760FB",
    'primary-120': "#0857E2",
    'primary-140': "#004ED4",
    // Colors/Neutral
    'neutral-0': "#FFFFFF",
    'neutral-10': "#F8F7F7",
    'neutral-20': "#F0EFEF",
    'neutral-30': "#DCDCDC",
    'neutral-40': "#9F9F9F",
    'neutral-50': "#767676",
    'neutral-90': "#252527",
    'neutral-100': "#1E1E1E",
    // Colors/Branding
    'dark-blue-100': "#001459",
    'dark-blue-120': "#03134B",
    'light-blue-100': "#63B9E9",
    // Colors/Badges
    'green-50': "#ECFCE5",
    'green-100': "#198155",
    'orange-50': "#FFEFD7",
    'orange-100': "#A05E03",
    'purple-50': "#F3F1FF",
    'purple-100': "#6B4EFF",
    'blue-50': "#E7FFFE",
    'blue-100': "#198E8E",
    'red-50': "#FFEAE5",
    'red-100': "#D32C07",
    // Colors/Tooltip
    'tooltip-75': "rgba(0, 0, 0, 0.75)",
    modalCloseButtonSize: 28,
    sidebarIndent: 40,
    sidebarWidth: 208,
    sidebarCollapsedWidth: 56,
    modalPadding: 24,
    tooltipOpacity: .9,
    fontSizeSM: 12,
    lineHeightSM: 1.6666666666666667,
};
export const i18n = {
    auth: {
        Email: "Email",
        Password: "Password",
        Name: "Nome",
        "Enter your Name": "Insira o seu nome",
        "Enter your Email": "Insira o seu email",
        "Enter your Password": "Insira a sua password",
        "Please confirm your Password": "Confirme a sua password",
        "Confirm Password": "Confirme a password",
        "Enter your Confirmation Code": "Insira o código",
        "Change Password": "Alterar password",
        "Forgot your password?": "Recuperar password",
        'Signing in': 'A efetuar login',
        Submitting: 'A enviar',
        Sending: 'A enviar',
        Changing: "A alterar",
        "Incorrect username or password.": "Verifique o email e a password.",
        'Your passwords must match': 'As passwords devem coincidir.',
        'User is disabled.': 'O utilizador está bloqueado.',
        'Invalid verification code provided, please try again.': 'Código de confirmação errado.',
        'Attempt limit exceeded, please try after some time.': 'Número de tentativas excedido. Por favor tente mais tarde.',
        "Invalid code provided, please request a code again.": "Código de confirmação errado. Clique em Reenviar código.",
        "Password does not conform to policy: Password not long enough": "A password deve ter, no mínimo, 8 caracteres",
        "Password does not conform to policy: Password must have uppercase characters": "A password deve ter letras maiúsculas",
        "Password does not conform to policy: Password must have lowercase characters": "A password deve ter letras minúsculas",
        "Password does not conform to policy: Password must have symbol characters": "A password deve ter caractéres especiais",
        "Password does not conform to policy: Password must have numeric characters": "A password deve ter caractéres numéricos"
    }
};
export const permissions = {
    CREATE_PROJECT: ({ userGroups }) => userGroups.includes("Administrador"),
    UPDATE_OR_DELETE_PROJECT: ({ userGroups }) => userGroups.includes("Administrador"),
    VIEW_ALL_PROJECTS: ({ userGroups }) => userGroups.includes("Administrador"),
    SHARE_PROJECT: ({ userGroups }) => !userGroups.includes("Externo"),
    VIEW_USERS_PAGE: ({ userGroups }) => userGroups.includes("Administrador"),
    VIEW_ATTACHMENTS: ({ userGroups }) => !userGroups.includes("Externo"),
    UPLOAD_FILE: ({ userGroups, userId, project }) => {
        if (userGroups.includes("Administrador"))
            return true;
        if (userGroups.includes("Interno")) {
            if (project)
                return some(project.members, { sub: userId });
            else
                return true; // Allow upload on project create
        }
        return false;
    },
    DOWNLOAD_FILE: ({ userGroups }) => !userGroups.includes("Externo"),
    DELETE_FILE: ({ userGroups }) => {
        if (userGroups.includes("Administrador"))
            return true;
        if (userGroups.includes("Interno"))
            return true;
        return false;
    },
    CREATE_TRIP: ({ userGroups, userId, project }) => {
        if (userGroups.includes("Administrador"))
            return true;
        if (userGroups.includes("Interno") && some(project.members, { sub: userId }))
            return true;
        return false;
    },
    UPDATE_OR_DELETE_TRIP: ({ userGroups, userId, project }) => {
        if (userGroups.includes("Administrador"))
            return true;
        if (userGroups.includes("Interno") && some(project.members, { sub: userId }))
            return true;
        return false;
    },
    CREATE_TRIP_POINTS: ({ userGroups, userId, project }) => {
        if (userGroups.includes("Administrador"))
            return true;
        if (userGroups.includes("Interno") && some(project.members, { sub: userId }))
            return true;
        return false;
    },
    UPDATE_OR_DELETE_TRIP_POINTS: ({ userGroups, userId, project }) => {
        if (userGroups.includes("Administrador"))
            return true;
        if (userGroups.includes("Interno") && some(project.members, { sub: userId }))
            return true;
        return false;
    },
};
export default {
    theme,
    i18n,
    permissions,
    logo: {
        darkBg: "/img/logo_dark_bg_colored.svg",
        whiteBg: "/img/logo_white_bg_colored.svg",
        small: "/img/logo_small.svg"
    },
    services: {
        projectManagement: {
            baseUrl: process.env.PROJECT_MANAGEMENT_BASE_URL,
        },
        nmaps: {
            baseUrl: process.env.NMAPS_BASE_URL,
            accessToken: process.env.NMAPS_ACCESS_TOKEN
        }
    },
    profileBaseUrl: process.env.PROFILE_BASE_URL,
    sentry: {
        enabled: process.env.SENTRY_ENABLED?.toLowerCase() == "true",
        dsn: process.env.SENTRY_DSN
    },
    auth: {
        region: process.env.AUTH_REGION,
        userPoolId: process.env.AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.AUTH_USER_POOL_APP_CLIENT_ID
    },
    env: process.env.ENV
};
