import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { theme } from "~/config";
import { textEllipsis1 } from "~/styles";
import { fileIcon } from "~/utils";
import { Loading } from "./loading";
export const isAsyncFile = (value) => typeof value["async"] == "boolean";
const asyncLoadingStyle = css({
    color: theme["neutral-30"],
    borderRadius: 6,
    position: "relative",
    height: 24,
    ".loading-container": {
        position: "absolute",
        width: "100%",
        top: 0
    }
});
export const LoadingFileRow = ({ asyncFile }) => {
    return (_jsxs("div", { className: asyncLoadingStyle, children: [_jsxs("div", { className: "d-flex align-center", children: [fileIcon(asyncFile.content_type), _jsx("p", { className: `mt-0 mb-0 ml-2 ${textEllipsis1}`, children: asyncFile.filename })] }), _jsx("div", { className: "loading-container", children: _jsx(Loading, { className: "pa-0 ma-0" }) })] }));
};
