import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { Card } from "antd";
import { format } from "date-fns";
import { ProjectAvatar, StatusTag, UserAvatarGroup, } from "~/components";
import { theme } from "~/config";
const style = css({
    fontSize: 16,
    color: theme["neutral-100"],
    "& .label": {
        color: theme["neutral-50"],
        marginBottom: 6,
        display: "block"
    },
    "& .grid-container": {
        display: "grid",
        gridTemplateColumns: "auto auto",
        marginTop: 20,
        gap: 16
    }
});
export const ProjectCard = ({ project, onClick }) => {
    return (_jsxs(Card, { className: style, onClick: onClick, children: [_jsx(ProjectAvatar, { project: project }), _jsxs("div", { className: "grid-container", children: [_jsxs("div", { children: [_jsx("span", { className: "type-body-4 label", children: "N\u00BA processo AIA" }), _jsx("span", { children: project.aia_process })] }), _jsxs("div", { children: [_jsx("span", { className: "type-body-4 label", children: "Data de cria\u00E7\u00E3o" }), _jsx("span", { children: format(project.created_date, "dd MMM yyyy") })] }), _jsxs("div", { children: [_jsx("span", { className: "type-body-4 label", children: "Membros" }), _jsx(UserAvatarGroup, { users: project.members })] }), _jsxs("div", { children: [_jsx("span", { className: "type-body-4 label", children: "Estado" }), _jsx(StatusTag.Project, { status: project.status })] })] })] }));
};
