// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@ndrive/nmaps-gl/dist/nmaps-gl.css";
import 'antd-css-utilities/utility.min.css';
import { theme } from "~/config";
import { amplifyGlobalTheme } from "./amplifyTheme";
import { antdGlobalStyle } from "./antdTheme";
const style = {
    "html": {
        fontFamily: theme["font-family"],
        fontSize: theme["base-font-size"],
        lineHeight: theme["base-line-height"] + "px"
    },
    "html, body, #app-root": {
        minHeight: "100vh"
    },
    "body": {
        margin: 0,
        overflow: "auto",
        WebkitFontSmoothing: "antialiased"
    },
    "h1, .type-heading-1": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 30,
        lineHeight: "35px",
        letterSpacing: -0.408
    },
    "h3, .type-heading-3": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 24,
        lineHeight: "28px"
    },
    ".type-subheading-2": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 17,
        lineHeight: "22px"
    },
    ".type-heading-7": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 20,
        lineHeight: "23px"
    },
    ".type-badge-1": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 14,
        lineHeight: "22px"
    },
    ".type-badge-4": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 16,
        lineHeight: "19px"
    },
    ".type-body-1": {
        fontWeight: theme["font-weight-regular"],
        fontSize: 16,
        lineHeight: "22px"
    },
    ".type-body-2": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 16,
        lineHeight: "19px",
        textDecorationLine: "underline"
    },
    ".type-body-3": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 16,
        lineHeight: "19px"
    },
    ".type-body-4": {
        fontWeight: theme["font-weight-medium"],
        fontSize: 14,
        lineHeight: "18px"
    },
    ".type-body-5": {
        fontWeight: theme["font-weight-regular"],
        fontSize: 14,
        lineHeight: "18px"
    },
    ".type-body-6": {
        fontWeight: theme["font-weight-regular"],
        fontSize: 14,
        lineHeight: "16px",
        textDecorationLine: "underline"
    },
    ".pointer": {
        cursor: "pointer"
    },
    ".flex-even": {
        flex: 1
    },
    ".filters-marker": {
        width: 6,
        height: 6,
        backgroundColor: theme["primary-100"],
        borderRadius: "50%",
        marginLeft: 5,
        alignSelf: "flex-start"
    },
    ".nmapsgl-map": {
        fontFamily: theme["font-family"],
        fontSize: theme["base-font-size"],
        lineHeight: theme["base-line-height"] + "px"
    },
    ".nmapsgl-popup .nmapsgl-popup-content ": {
        display: "flex",
        flexDirection: "column",
        fontSize: 14,
        padding: 16,
        borderRadius: 8,
        backgroundColor: theme["neutral-0"]
    },
    ".nmapsgl-popup .nmapsgl-popup-tip": {
        borderTopColor: theme["neutral-0"]
    },
    ".ant-notification .ren-notification": {
        ".ant-notification-notice-with-icon": {
            display: "flex",
            alignItems: "center",
            ".ant-notification-notice-icon": {
                fontSize: 14
            },
            ".ant-notification-notice-message": {
                fontSize: 14,
                padding: 0,
                marginInlineStart: 24
            }
        },
        ".ant-notification-close-icon": {
            fontSize: 14
        }
    }
};
export { Button, FormItem, antdThemeConfig } from "./antdTheme";
export * from "./utils";
export const globalStyle = [
    style,
    ...antdGlobalStyle,
    amplifyGlobalTheme
];
