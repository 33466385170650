import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingOutlined } from '@ant-design/icons';
import { Authenticator, translations, useAuthenticator } from "@aws-amplify/ui-react";
import { Global } from "@emotion/react";
import nmapsgl from "@ndrive/nmaps-gl";
import { BrowserTracing, Replay, init as initSentry, reactRouterV6Instrumentation, setUser, wrapCreateBrowserRouter } from "@sentry/react";
import { App, ConfigProvider, Spin } from 'antd';
import pt_PT from "antd/locale/pt_PT";
import { Amplify, I18n } from 'aws-amplify';
import { setDefaultOptions } from 'date-fns';
import { pt } from "date-fns/locale";
import 'dayjs/locale/pt';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate, RouterProvider, createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { EventsProvider } from "~/contexts";
import { Dashboard, Files, Map, Projects, Users } from "~/modules";
import { ErrorFallback, InfoTooltipProvider, registerModals } from './components';
import config, { i18n } from './config';
import { AuthLayout, BaseLayout, MainLayout } from "./layouts";
import { antdThemeConfig, globalStyle } from "./styles";
initSentry({
    dsn: config.sentry.dsn,
    integrations: [
        new BrowserTracing({ routingInstrumentation: reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes) }),
        new Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    environment: config.env,
    enabled: config.sentry.enabled
});
Spin.setDefaultIndicator(_jsx(LoadingOutlined, { spin: true }));
Amplify.configure({ Auth: config.auth });
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage("pt", i18n.auth);
I18n.setLanguage('pt');
setDefaultOptions({ locale: pt });
registerModals();
nmapsgl.baseApiUrl = config.services.nmaps.baseUrl;
nmapsgl.accessToken = config.services.nmaps.accessToken;
const RoutesProvider = () => {
    const { user } = useAuthenticator(context => [context.user]);
    useEffect(() => {
        let sentryUser = null;
        if (user && user.attributes)
            sentryUser = { id: user.username, email: user.attributes.email };
        setUser(sentryUser);
    }, [user]);
    const router = wrapCreateBrowserRouter(createBrowserRouter)([
        {
            path: '/',
            element: _jsx(BaseLayout, {}),
            errorElement: _jsx(ErrorFallback, {}),
            children: [
                { path: '/', index: true, element: _jsx(Navigate, { replace: true, to: "/dashboard" }) },
                { path: '/dashboard', lazy: Dashboard.DashboardPage, handle: { title: "Dashboard" } },
                { path: '/map', lazy: Map.MapPage, handle: { title: "Mapa" } },
                {
                    element: _jsx(MainLayout, {}), children: [
                        { path: '/projects', lazy: Projects.ProjectsListPage, handle: { title: "Projetos" } },
                        { path: '/projects/:id', element: _jsx(Navigate, { replace: true, to: "trips" }) },
                        { path: '/projects/:id/:tabKey', lazy: Projects.ProjectDetailsPage, handle: { title: "Projetos", goBack: true } },
                        { path: '/files', element: _jsx(Navigate, { replace: true, to: "all" }) },
                        { path: '/files/:tabKey', lazy: Files.FilesPage, handle: { title: "Ficheiros" } },
                        { path: '/profile', lazy: Users.ProfilePage, handle: { title: "Perfil" } },
                        { path: '/users', lazy: Users.UsersPage, handle: { title: "Utilizadores" } },
                    ]
                }
            ]
        },
        {
            path: '/login',
            element: _jsx(AuthLayout, {})
        }
    ]);
    return _jsx(RouterProvider, { router: router });
};
createRoot(document.getElementById('app-root')).render(_jsxs(ConfigProvider, { theme: antdThemeConfig, locale: pt_PT, children: [_jsx(Global, { styles: globalStyle }), _jsx(HelmetProvider, { children: _jsx(Authenticator.Provider, { children: _jsx(InfoTooltipProvider, { children: _jsx(App, { notification: { placement: "bottomRight" }, children: _jsx(EventsProvider, { children: _jsx(RoutesProvider, {}) }) }) }) }) })] }));
