import { jsx as _jsx } from "react/jsx-runtime";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEventEmitter } from "ahooks";
import { App } from "antd";
import { EventSourcePolyfill } from 'event-source-polyfill';
import { createContext, useContext, useEffect } from "react";
import config from "~/config";
import { globalNotificationConfig } from "~/utils";
const EventsContext = createContext(undefined);
export const useEvents = () => useContext(EventsContext);
export const EventsProvider = ({ children }) => {
    const eventEmitter = {
        "FILE_PROCESSING": useEventEmitter(),
        "NOTIFICATION": useEventEmitter()
    };
    const { notification } = App.useApp();
    const { user } = useAuthenticator(context => [context.user]);
    useEffect(() => {
        const accessToken = user?.getSignInUserSession()?.getAccessToken()?.getJwtToken();
        if (!accessToken)
            return;
        const source = new EventSourcePolyfill(`${config.services.projectManagement.baseUrl}/events/subscribe`, {
            headers: { 'Authorization': `Bearer ${accessToken}` }
        });
        source.addEventListener('FILE_PROCESSING', (event) => {
            const data = JSON.parse(event['data']);
            eventEmitter.FILE_PROCESSING.emit(data);
            notification.destroy("FILE_PROCESSING");
            const notificationProps = { ...globalNotificationConfig, key: "FILE_PROCESSING" };
            if (data["success"]) {
                notificationProps.type = "success";
                notificationProps.message = "Ficheiro processado com sucesso";
            }
            else {
                notificationProps.type = "error";
                notificationProps.message = "Erro no processamento do ficheiro";
            }
            notification.open(notificationProps);
        });
        source.addEventListener('NOTIFICATION', (event) => {
            const data = JSON.parse(event['data']);
            eventEmitter.NOTIFICATION.emit(data);
        });
    }, [eventEmitter.FILE_PROCESSING, eventEmitter.NOTIFICATION, notification, user]);
    return (_jsx(EventsContext.Provider, { value: { eventEmitter }, children: children }));
};
