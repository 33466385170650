import { jsx as _jsx } from "react/jsx-runtime";
import { Empty } from "antd";
import { createStyles } from "antd-style";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { Button } from "~/styles";
const useStyles = createStyles(({ css }, props) => {
    const hasImage = !!props.image;
    return css({
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        height: '100%',
        ".ant-btn": {
            minWidth: 177,
            height: 48,
            fontSize: 16,
            border: 6,
        },
        ".ant-empty-image": {
            ...(!hasImage && { display: "none" }),
            height: "auto",
            maxHeight: 100,
        },
        ".ant-empty-description": {
            margin: hasImage ? "14px 0 46px" : "28px 0 28px",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 400,
            color: theme["neutral-50"]
        },
        ".ant-empty-footer": {
            marginTop: 0
        }
    });
});
export const EmptyContent = ({ button, ...emptyProps }) => {
    const { styles, cx } = useStyles({ image: emptyProps.image });
    return (_jsx(Empty, { ...emptyProps, className: cx(styles, emptyProps.className), children: button && (_jsx(Button, { icon: _jsx(SvgIcon, { name: "ic_plus", size: 17 }), type: "primary", onClick: button.onClick, children: button.content })) }));
};
