import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NiceModal from "@ebay/nice-modal-react";
import { css } from "@emotion/css";
import { Grid, Layout, Menu } from "antd";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation, useMatches, useNavigate } from "react-router-dom";
import config, { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { customScroll } from "~/styles";
import { usePermissions, withPermissions } from "~/utils";
const style = css({
    minHeight: "100vh",
    ".ant-layout-sider": [{
            position: "fixed",
            minHeight: "100vh",
            overflow: "auto",
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 1060,
            ".ant-menu-item": {
                fontWeight: theme["font-weight-medium"],
            },
            ".ant-menu-inline-collapsed > .ant-menu-item": {
                paddingInline: "calc(50% - 9px)"
            },
            ".ant-layout-sider-children": {
                display: "flex",
                flexFlow: "column"
            },
            ".logo": {
                paddingLeft: theme.sidebarIndent,
                paddingTop: 40,
                paddingBottom: 40,
                "img": {
                    width: 55.5,
                    height: 76.5
                }
            },
            "&-collapsed": {
                ".logo": {
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: 0,
                    "img": {
                        width: 20
                    }
                }
            }
        }, customScroll]
});
const Component = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const location = useLocation();
    const matches = useMatches();
    const navigate = useNavigate();
    const permissions = usePermissions(["VIEW_USERS_PAGE", "VIEW_ALL_PROJECTS"]);
    const screens = Grid.useBreakpoint();
    const selectedKeys = [location.pathname.split('/')[1]];
    const { title } = (matches.at(-1)?.handle || {});
    const menuItems = useMemo(() => {
        const projectsLink = permissions.VIEW_ALL_PROJECTS ? "/projects" : "/projects?myProjects=true";
        const a = [
            { key: "dashboard", link: "/dashboard", label: "Dashboard", icon: _jsx(SvgIcon, { name: "ic_dashboard" }) },
            { key: "projects", link: projectsLink, label: "Projetos", icon: _jsx(SvgIcon, { name: "ic_projects" }) },
            { key: "files", link: "/files", label: "Ficheiros", icon: _jsx(SvgIcon, { name: "ic_files" }) },
            { key: "map", link: "/map", label: "Mapa", icon: _jsx(SvgIcon, { name: "ic_map" }) }
        ];
        if (permissions.VIEW_USERS_PAGE)
            a.push({ key: "users", link: "/users", label: "Utilizadores", icon: _jsx(SvgIcon, { name: "ic_users" }) });
        return a;
    }, [permissions.VIEW_ALL_PROJECTS, permissions.VIEW_USERS_PAGE]);
    const onSiderCollapse = (collapsed) => {
        setSidebarCollapsed(collapsed);
    };
    const onMenuClick = ({ key }) => {
        const item = menuItems.find(e => e.key === key);
        navigate(item.link);
    };
    return (_jsxs(NiceModal.Provider, { children: [_jsxs(Helmet, { defaultTitle: "VeR", titleTemplate: "VeR | %s", children: [_jsx("meta", { charSet: "utf-8" }), title && _jsx("title", { children: title })] }), _jsxs(Layout, { className: style, children: [_jsxs(Layout.Sider, { collapsible: true, width: theme.sidebarWidth, breakpoint: "lg", collapsedWidth: theme.sidebarCollapsedWidth, onCollapse: onSiderCollapse, children: [_jsx("div", { className: "logo", children: _jsx("img", { src: sidebarCollapsed ? config.logo.small : config.logo.darkBg }) }), _jsx(Menu, { onClick: onMenuClick, theme: "dark", mode: "inline", selectedKeys: selectedKeys, items: menuItems, inlineIndent: theme.sidebarIndent })] }), _jsx(Layout, { style: { marginLeft: screens.lg && !sidebarCollapsed ? theme.sidebarWidth : theme.sidebarCollapsedWidth }, children: _jsx(Outlet, {}) })] })] }));
};
export const BaseLayout = withPermissions(Component, []);
