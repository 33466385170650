import { Auth } from "aws-amplify";
import axios from "axios";
import config from "~/config";
const fieldTransformer = {
    "delivery_date": (value) => new Date(value),
    "updated_date": (value) => new Date(value),
    "creation_date": (value) => new Date(value),
    "created_date": (value) => new Date(value),
    "trip_date": (value) => new Date(value),
};
const client = axios.create({
    baseURL: config.services.projectManagement.baseUrl,
    headers: { "Content-type": "application/json" },
    paramsSerializer: { indexes: null },
    transformResponse: data => data && JSON.parse(data, (key, value) => fieldTransformer[key] ? fieldTransformer[key](value) : value)
});
client.interceptors.request.use(config => Auth.currentSession().then((session) => {
    config.headers.Authorization = "Bearer " + session.getAccessToken().getJwtToken();
    return Promise.resolve(config);
}), e => Promise.reject(e));
client.interceptors.response.use(response => response, (error) => {
    if (error.response && error.response.status === 401) {
        Auth.signOut();
    }
    return Promise.reject(error);
});
// Projects
export function getProjects(params = {}) {
    return client.get('/projects/', { params })
        .then(v => v.data);
}
export function getProjectsShortInfo(params) {
    return client.get('/projects/shortinfo', { params })
        .then(v => v.data);
}
export function getProject(id) {
    return client.get(`/projects/${id}`)
        .then(v => v.data);
}
export function getProjectAttachments(id, params) {
    return client.get(`/projects/${id}/files`, { params })
        .then(v => v.data);
}
export function createProject(data) {
    return client.post('/projects/', data).then(v => v.data);
}
export function updateProject(data, id) {
    return client.put(`/projects/${id}`, data);
}
export function deleteProject(id) {
    return client.delete(`/projects/${id}`);
}
export function getProjectTripPoints(project_id) {
    return client.get(`/projects/${project_id}/trip-points`).then(v => v.data);
}
export function getProjectMembers(project_id) {
    return client.get(`/projects/${project_id}/members`).then(v => v.data);
}
// Users
export function getUsers() {
    return client.get('/users/').then(v => v.data);
}
export function getUserGroups() {
    return client.get('/users/groups').then(v => v.data);
}
export function getUserCounters(id) {
    return client.get(`/users/${id}/counters`).then(v => v.data);
}
export function createUser(data) {
    return client.post('/users/', data).then(v => v.data);
}
export function updateUser(data) {
    return client.put('/users/', data).then(v => v.data);
}
export function resendUser(email) {
    return client.post('/users/resend', { email });
}
export function disableUser(sub) {
    return client.post('/users/disable', { sub });
}
export function enableUser(sub) {
    return client.post('/users/enable', { sub });
}
export function deleteUser(body) {
    return client.delete('/users/', { data: body });
}
// Trips
export function getTrips(params) {
    return client.get('/trips/', { params }).then(v => v.data);
}
export function getTripsShortInfo(params) {
    return client.get('/trips/shortinfo', { params })
        .then(v => v.data);
}
export function getTripTripPoints(trip_id) {
    return client.get(`/trips/${trip_id}/trip-points`).then(v => v.data);
}
export function getTrip(id) {
    return client.get(`/trips/${id}`)
        .then(v => v.data);
}
export function createTrip(data) {
    return client.post('/trips/', data).then(v => v.data);
}
export function updateTrip(id, data) {
    return client.put(`/trips/${id}`, data);
}
export function getTripAttachments(id) {
    return client.get(`/trips/${id}/files`).then(v => v.data);
}
export function deleteTrip(id) {
    return client.delete(`/trips/${id}`);
}
export function getTripsDashboard(params) {
    return client.get('/trips/dashboard', { params }).then(v => v.data);
}
// Files
export function getPresignedPost(data) {
    return client.post('/files/presigned_post', data).then(v => v.data);
}
export function uploadFile(presignedPostUrl, file, uploadProgress) {
    const formData = new FormData();
    Object.entries(presignedPostUrl.fields).forEach(([k, v]) => formData.append(k, v));
    formData.append('file', file);
    return axios.post(presignedPostUrl.url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => uploadProgress(progressEvent)
    });
}
export function processFile(data) {
    const { type, ...payload } = data;
    const url = type === 0 ? '/files/' : '/files/geo';
    return client.post(url, payload).then(v => v.data);
}
export function getAttachments(params = {}) {
    return client.get('/files/', { params }).then(v => v.data);
}
export function getExtensions() {
    return client.get('/files/extensions').then(v => v.data.map(e => e["extension"]));
}
export function getOwners() {
    return client.get('/files/owners').then(v => v.data);
}
export function deleteAttachment(id) {
    return client.delete(`/files/${id}`);
}
// Search
export function search(params) {
    return client.get('/search/', { params }).then(v => v.data);
}
// Trip Points
export function createTripPoint(data) {
    return client.post('/points/', data).then(v => v.data);
}
export function updateTripPoint(data, id) {
    return client.put(`/points/${id}`, data);
}
export function deleteTripPoint(id) {
    return client.delete(`/points/${id}`);
}
export function bulkTripPoints(data) {
    return client.post('/points/bulk', data).then(v => v.data);
}
// Notifications
export function getNotifications() {
    return client.get('/notifications/').then(v => v.data);
}
export function getNotificationsCounter() {
    return client.get('/notifications/counters').then(v => v.data);
}
export function markNotificationAsRead(id) {
    const params = new URLSearchParams();
    if (id) {
        params.append('id', id.toString());
    }
    return client.put(`/notifications/mark-as-read`, { params });
}
export function archive() {
    return client.put(`/notifications/archive`);
}
