import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { css, cx } from "@emotion/css";
import { Col, DatePicker, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { pick } from "lodash-es";
import { useEffect, useState } from "react";
import { AvatarText, ProjectAvatar, StatusTag } from "~/components";
import { ProjectManagementService } from "~/services";
import { FormItem } from "~/styles";
import { convertWGS84IntoETRS89, defaultModalProps, usePermissions } from "~/utils";
import { FileList } from "./file-list";
const style = css({
    "& .project-avatar .avatar-text p": {
        fontSize: 24,
        lineHeight: "28px"
    },
    "& .members": {
        margin: 0,
        padding: 0,
        display: "grid",
        listStyleType: "none",
        gridTemplateColumns: "repeat(4, 194px)",
        columnGap: 40,
        rowGap: 18
    }
});
export const ProjectDetailModal = createModal(({ project }) => {
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const [form] = useForm();
    const permissions = usePermissions(["VIEW_ATTACHMENTS"]);
    const [attachments, setAttachments] = useState([]);
    const formValues = {
        ...pick(project, ["aia_process", "description", "members", "name", "status"]),
        created_date: dayjs(project.created_date),
        image: project.image ? [{ uid: '-1', name: 'image.png', status: 'done', 'url': project.image || "" }] : [],
        coordinates: convertWGS84IntoETRS89(`${project["latitude"]},${project["longitude"]}`)
    };
    useEffect(() => {
        project && ProjectManagementService.getProjectAttachments(project.id, { trip: false }).then(setAttachments);
    }, [project]);
    return (_jsx(Modal, { ...defaultProps, className: cx("project-detail-modal", style), title: _jsx(ProjectAvatar, { project: project, lineClamp: 1 }), footer: null, width: 944, children: _jsx(Form, { disabled: true, form: form, layout: "vertical", initialValues: formValues, children: _jsxs(Row, { gutter: 24, children: [_jsx(Col, { span: 12, children: _jsx(FormItem, { label: "N\u00BA processo AIA", name: "aia_process", children: _jsx(Input, {}) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Data de cria\u00E7\u00E3o", name: "created_date", children: _jsx(DatePicker, { className: "w-100", format: value => value.locale('pt-PT').format("D MMM YYYY"), suffixIcon: null }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Geolocaliza\u00E7\u00E3o", name: "coordinates", children: _jsx(Input, {}) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Estado", children: _jsx(StatusTag.Project, { status: formValues.status }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Descri\u00E7\u00E3o", name: "description", children: _jsx(Input.TextArea, { autoSize: true, placeholder: "Sem descri\u00E7\u00E3o", style: { minHeight: 40 } }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Membros", children: _jsx("ul", { className: "members", children: formValues.members.map(member => _jsx("li", { children: _jsx(AvatarText.User, { user: member }) }, member.sub)) }) }) }), permissions.VIEW_ATTACHMENTS &&
                        _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Anexos", children: _jsx(FileList, { files: attachments.filter(a => a.type == 0), placeholder: "Sem anexos" }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Ficheiros GEO", children: _jsx(FileList, { files: attachments.filter(a => a.type == 1), placeholder: "Sem ficheiros GEO" }) }) })] }) }) }));
});
