import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserOutlined } from '@ant-design/icons';
import { HighlightMatch } from "@aws-amplify/ui-react";
import { css } from "@emotion/css";
import { Avatar, Tooltip } from "antd";
import { cx } from "antd-style";
import { theme } from "~/config";
import { textEllipsis1 } from "~/styles";
import { fileIcon, formatBytes } from "~/utils";
const style = css({
    display: "flex",
    alignItems: "center",
    ".text, .secondary-text": textEllipsis1,
    ".text": {
        color: theme["neutral-100"]
    },
    ".secondary-text": {
        color: theme["neutral-50"]
    }
});
const AvatarText = ({ shape, onClick, size = 36, text, secondaryText, icon, src, highlightText, initials, color, showTooltip }) => {
    const avatarProps = { shape, size, icon, src };
    avatarProps.style = { minWidth: size };
    if (color != undefined)
        avatarProps.style.backgroundColor = color;
    if (initials != undefined)
        avatarProps.children = initials;
    const avatar = _jsx(Avatar, { ...avatarProps, className: cx({ "mr-3": !showTooltip }) });
    return (_jsxs("div", { className: style, onClick: onClick, children: [showTooltip ? _jsxs(Tooltip, { title: text || "Não Atribuído", children: [" ", avatar, " "] }) : avatar, !showTooltip && (_jsxs("div", { className: 'd-block', children: [_jsx("span", { className: "type-body-1 text", children: highlightText ? (_jsx(HighlightMatch, { query: highlightText.query, children: text })) : (text || "Não Atribuído") }), secondaryText && (_jsx("span", { className: "type-body-5 secondary-text", children: secondaryText }))] }))] }));
};
const UserAvatar = ({ user, ...props }) => {
    const avatarTextProps = {
        src: user.picture,
        icon: _jsx(UserOutlined, {}),
        text: user.name,
        secondaryText: user["custom:office"]
    };
    return (_jsx(AvatarText, { ...avatarTextProps, ...props }));
};
const FileAvatar = ({ file, ...props }) => {
    const avatarTextProps = {
        icon: fileIcon(file.content_type),
        text: file.name,
        secondaryText: formatBytes(file.size),
        shape: "square"
    };
    return (_jsx(AvatarText, { ...avatarTextProps, ...props }));
};
const TripAvatar = ({ trip, ...props }) => {
    const avatarTextProps = {
        text: trip.name,
        initials: `#${trip.sequence_id}`,
        shape: "square"
    };
    return (_jsx(AvatarText, { ...avatarTextProps, ...props }));
};
const ProjectAvatar = ({ project, ...props }) => {
    const avatarTextProps = {
        src: project.image,
        text: project.name,
        initials: project.initials,
        color: !project.image && project.color,
        secondaryText: `Nº processo AIA ${project.aia_process}`,
        shape: "square"
    };
    return (_jsx(AvatarText, { ...avatarTextProps, ...props }));
};
export const UserAvatarGroup = ({ avatarSize = 30, maxCount = 3, users, className = "" }) => {
    return (_jsx(Avatar.Group, { maxCount: maxCount, size: avatarSize, className: className, maxStyle: { fontWeight: 500, fontSize: 12, cursor: 'default' }, maxPopoverTrigger: null, children: users.map((m) => {
            const avatarProps = m.picture ? { src: m.picture } : { icon: _jsx(UserOutlined, {}) };
            return (_jsxs(Tooltip, { title: m.name || "Não Atribuído", children: [" ", _jsx(Avatar, { ...avatarProps }, m.sub)] }, m.sub));
        }) }));
};
AvatarText.User = UserAvatar;
AvatarText.File = FileAvatar;
AvatarText.Trip = TripAvatar;
AvatarText.Project = ProjectAvatar;
export default AvatarText;
