import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { Modal, Space } from "antd";
import { Button } from "~/styles";
import { defaultModalProps } from "~/utils";
export const ConfirmationModal = createModal(({ sentence, extraSentence, confirmationLabel, cancelLabel, className, width = 308, onCancel }) => {
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const cancelFn = onCancel ? () => { onCancel(); defaultProps.onCancel(); } : defaultProps.onCancel;
    const modalProps = {
        ...defaultProps,
        width,
        footer: (_jsxs(Space, { size: 24, className: "full-width", children: [_jsx(Button, { onClick: cancelFn, children: cancelLabel || 'Cancelar' }), _jsx(Button, { type: "primary", onClick: defaultProps.onOk, children: confirmationLabel || 'Apagar' })] }))
    };
    return (_jsxs(Modal, { ...modalProps, className: className, closable: false, children: [_jsx("p", { className: "type-body-3 px-1 my-0 text-center", children: sentence || 'Tem a certeza que pretende apagar?' }), extraSentence && (_jsx("p", { className: "type-body-5 px-1 my-0 mt-2 text-center", children: extraSentence }))] }));
});
