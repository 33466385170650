import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserOutlined } from '@ant-design/icons';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { css } from "@emotion/css";
import { Avatar, Popover, theme } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "~/config";
import { SvgIcon } from "~/icons";
import { Button } from "~/styles";
import AvatarText from "./avatar";
const style = css({
    ".ant-popover-title": {
        padding: "20px 20px 0px",
    },
    ".ant-popover-inner": {
        padding: 0,
        width: 274,
        "ul": {
            listStyleType: "none",
            margin: 0,
            padding: 0,
            "li": {
                padding: 20,
                display: "flex",
                alignItems: "center",
                "&:not(:first-child)": {
                    borderTop: "1px solid #F0EFEF",
                },
                "&:not(.static)": {
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: theme["neutral-10"]
                    }
                }
            }
        }
    }
});
export const LoggedUserPopover = () => {
    const { user, signOut } = useAuthenticator(context => [context.user, context.authStatus]);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const content = useMemo(() => {
        const avatarUser = {
            name: user.attributes?.name,
            picture: user.attributes?.picture ? `${config.profileBaseUrl}${user.attributes?.picture}` : "",
            "custom:office": user.attributes && user.attributes["custom:office"]
        };
        return (_jsxs("ul", { children: [_jsx("li", { style: { paddingTop: 16 }, className: "static", children: _jsx(AvatarText.User, { user: avatarUser }) }), _jsxs("li", { onClick: () => { setOpen(false); navigate("/profile"); }, children: [_jsx(SvgIcon, { name: "ic_profile", className: "ml-2 mr-5" }), " Perfil"] }), _jsxs("li", { onClick: () => signOut(), children: [_jsx(SvgIcon, { name: "ic_leave", className: "ml-2 mr-5" }), " Terminar Sess\u00E3o"] })] }));
    }, [navigate, signOut, user.attributes]);
    const popoverProps = {
        content,
        open,
        onOpenChange: setOpen,
        overlayClassName: style,
        title: (_jsxs("div", { className: "d-flex justify-space-between align-center", children: [_jsx("div", { children: "Notifica\u00E7\u00F5es" }), _jsx(Button, { style: { fontSize: 12 }, type: "link", children: "Marcar todas como lidas" })] }))
    };
    const avatarProps = user.attributes?.picture ?
        { src: `${config.profileBaseUrl}${user.attributes.picture}` } :
        { icon: _jsx(UserOutlined, {}), style: { lineHeight: "32px" } };
    return (_jsx(Popover, { ...popoverProps, placement: "bottomRight", title: "Conta", arrow: false, trigger: "click", children: _jsx(Avatar, { "data-cy": "logged-user-popover", size: 40, ...avatarProps }) }));
};
