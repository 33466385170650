import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css, cx } from "@emotion/css";
import { GeolocateControl, Map, Marker, NavigationControl, Popup } from "@ndrive/nmaps-gl";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { NMaps } from "~/services";
import { Button } from "~/styles";
import { MapContext, convertWGS84IntoETRS89 } from "~/utils";
import { CoordinateControl } from "./coordinate-control";
const geolocationInputStyle = css({
    height: 40,
    display: "flex",
    borderRadius: 6,
    border: `1px solid ${theme["neutral-20"]}`,
    padding: "0px 10px",
    transition: "all .2s",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
        borderColor: theme["primary-80"],
        borderInlineEndWidth: 1
    },
    ".members-name": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    ".icon": {
        width: 30,
        height: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme["neutral-10"],
        borderRadius: "50%",
        marginRight: 6
    }
});
const modalStyle = css({
    ".ant-modal-content .ant-modal-close": {
        right: "unset",
        left: 24,
        ".ant-modal-close-x": {
            fontSize: 28,
        }
    },
    ".ant-modal-content .ant-modal-body": {
        padding: "0px !important",
        overflowY: "hidden",
        height: 500
    },
    "&.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title": {
        paddingLeft: 64,
        paddingRight: theme.modalPadding
    },
    ".apply-button": {
        height: 40,
        width: 206,
        backgroundColor: theme["primary-100"],
        color: theme["neutral-0"],
        "&:disabled": {
            opacity: .6
        }
    },
    ".map-container": {
        height: "100%",
        width: "100%",
        ".marker": {
            backgroundImage: "url('/img/ic_map_pin.svg')",
            backgroundSize: "24px 50px",
            width: 24,
            height: 50
        },
        ".nmapsgl-popup-tip": {
            border: "none"
        }
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-zoom-in .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_zoom_in.svg')"
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-zoom-out .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_zoom_out.svg')"
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-geolocate .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_location_default.svg')"
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-geolocate:disabled .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_location_disabled.svg')"
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-geolocate.nmapsgl-ctrl-geolocate-active .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_location_active.svg')"
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-geolocate.nmapsgl-ctrl-geolocate-active-error .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_location_error.svg')"
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-geolocate.nmapsgl-ctrl-geolocate-background .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_location_background.svg')"
    },
    "& .nmapsgl-ctrl button.nmapsgl-ctrl-geolocate.nmapsgl-ctrl-geolocate-background-error .nmapsgl-ctrl-icon": {
        backgroundImage: "url('/img/ic_location_background_error.svg')"
    },
    "& .nmapsgl-ctrl-icon": {
        backgroundSize: "20px 20px"
    },
    "& .nmapsgl-ctrl-group": {
        borderRadius: 8,
        "& button": {
            width: 34,
            height: 34,
        }
    },
    "& .loader": {
        border: "6px solid #f3f3f3",
        borderTop: "6px solid #3f60f9",
        borderRadius: "50%",
        width: 28,
        height: 28,
        animation: "spin 2s linear infinite"
    },
    "@-webkit-keyframes spin": {
        "0%": {
            transform: "rotate(0deg)"
        },
        "100%": {
            transform: "rotate(360deg)"
        },
    }
});
export const MapPicker = ({ value, onChange: _onChange, onModalShow, id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [marker, setMarker] = useState(null);
    const [popup, setPopup] = useState(null);
    const [map, setMap] = useState();
    const [mapRef, setMapRef] = useState(null);
    const [inputValue, setInputValue] = useState(value);
    useEffect(() => onModalShow?.(isModalOpen), [isModalOpen, onModalShow]);
    const onChange = () => {
        _onChange?.(inputValue);
        setIsModalOpen(false);
    };
    const onPressEnter = (value) => {
        const coords = [value[1], value[0]];
        marker && marker.setLngLat(coords);
        map.jumpTo({ center: coords, zoom: 14 });
        setInputValue(value.join(","));
        if (popup) {
            popup.setHTML("<div class=\"loader\"></div>");
            popupHTML(`${value[0]},${value[1]}`).then(html => popup.setHTML(html));
        }
    };
    const popupHTML = async (value) => {
        const location = value.split(",");
        const label = await NMaps.reverseGeocode({ location: { lat: location[0], lng: location[1] }, result_types: ["area"] })
            .then((res) => {
            if (res.length != 0) {
                return res[0].formatted_address;
            }
            else {
                return "";
            }
        })
            .catch(() => { return ""; });
        return (`<div>
                <p class="my-0 type-body-4">${convertWGS84IntoETRS89(value)}</p>
                <p class="my-0 mt-1 type-body-5" style="color: ${theme["neutral-50"]}">${label}</p>
            </div>`);
    };
    useEffect(() => {
        if (!map || !marker || !popup)
            return;
        map.on('move', () => {
            marker.setLngLat(map.getCenter());
        });
        map.on('moveend', () => {
            const { lat, lng } = map.getCenter();
            setInputValue(`${lat},${lng}`);
            popup.setHTML("<div class=\"loader\"></div>");
            popupHTML(`${lat},${lng}`).then(html => marker.getPopup().setHTML(html));
        });
    }, [map, marker, popup]);
    useEffect(() => {
        if (!mapRef)
            return;
        const map = new Map({
            container: mapRef,
            bounds: [-10.038978, 42.417783, -6.178259, 36.697415],
            rightClick: false
        });
        setMap(map);
        map.on("load", () => {
            map.resize();
            map.fitBounds([-10.038978, 42.417783, -6.178259, 36.697415], { animate: false });
            const coordinates = inputValue || `${map.getCenter().lat},${map.getCenter().lng}`;
            const popup = new Popup({ offset: 25, closeOnClick: false, closeButton: false, anchor: "top" });
            setPopup(popup);
            popup.setHTML("<div class=\"loader\"></div>");
            popupHTML(coordinates).then(html => popup.setHTML(html));
            const coordinatesValues = coordinates.split(",");
            const el = document.createElement("div");
            el.className = "marker pointer";
            const m = new Marker(el).setLngLat([coordinatesValues[1], coordinatesValues[0]]).setPopup(popup).addTo(map).togglePopup();
            setMarker(m);
            const zoom = inputValue ? 14 : 6;
            map.jumpTo({ center: m.getLngLat(), zoom });
        });
        map.addControl(new NavigationControl({ showCompass: false }), 'right-center');
        map.addControl(new GeolocateControl({ positionOptions: { enableHighAccuracy: true }, trackUserLocation: true }), 'bottom-right');
    }, [mapRef]);
    useEffect(() => {
        if (!mapRef)
            return;
        const observer = new ResizeObserver(() => {
            map?.resize();
        });
        observer.observe(mapRef);
        return () => {
            observer.disconnect();
        };
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: geolocationInputStyle, onClick: () => setIsModalOpen(true), id: id, children: [_jsxs("div", { className: "d-flex align-center", style: { maxWidth: "calc(100% - 22px)" }, children: [_jsx("div", { className: "icon", children: _jsx(SvgIcon, { name: "ic_geolocation", size: 20, color: theme["neutral-50"] }) }), inputValue ? (_jsx("span", { children: convertWGS84IntoETRS89(inputValue) })) :
                                _jsx("span", { style: { color: theme["neutral-50"] }, children: "Latitude,Longitude (ETRS89)" })] }), _jsx(SvgIcon, { name: "ic_right", size: 22, color: theme["neutral-50"] })] }), _jsx(Modal, { className: modalStyle, width: 944, centered: true, destroyOnClose: true, closeIcon: _jsx(SvgIcon, { name: "ic_back" }), open: isModalOpen, maskClosable: false, title: "Geolocaliza\u00E7\u00E3o", onCancel: () => {
                    setIsModalOpen(false);
                    setInputValue(value);
                }, footer: _jsx(Button, { type: "primary", className: "apply-button", onClick: () => onChange(), disabled: value == inputValue || !inputValue, children: "Aplicar" }), children: _jsx("div", { className: cx("map-container"), ref: ref => setMapRef(ref), children: !!map && (_jsx(MapContext.Provider, { value: map, children: _jsx(CoordinateControl, { onPressEnter: onPressEnter }) })) }) })] }));
};
