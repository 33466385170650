import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useModal } from "@ebay/nice-modal-react";
import { cx } from "@emotion/css";
import { App, Dropdown, Grid, Table } from "antd";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { AvatarText, EmptyContent, Loading } from "~/components";
import { isAsyncFile, LoadingFileRow } from "~/components/loading-file-row";
import { theme } from "~/config";
import { useEvents } from "~/contexts";
import { SvgIcon } from "~/icons";
import { ProjectManagementService, S3 } from "~/services";
import { Button, highlightRow, idLabelStyle, noWrap, textEllipsis1 } from "~/styles";
import { fileIcon, formatBytes, getResponsiveValue, globalNotificationConfig, numberFormatter, usePermissions, usePermissionsFn } from "~/utils";
const sorterWrapper = (a, b, sorter) => {
    if (isAsyncFile(a))
        return 0;
    if (isAsyncFile(b))
        return 0;
    return sorter(a, b);
};
const download = (file, filename) => {
    S3.getObjectURL(file)
        .then((objectUrl) => {
        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = filename;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(objectUrl);
    });
};
const actions = (record, { onDeleteClick, permissionsFn }) => {
    const a = [
        { key: "download", label: _jsx("div", { onClick: () => download(record.path, record.name), children: "Download" }), icon: _jsx(SvgIcon, { name: "ic_download" }) }
    ];
    if (permissionsFn.DELETE_FILE({ file: record }))
        a.push({ key: "delete", label: "Apagar", icon: _jsx(SvgIcon, { name: "ic_bin" }), danger: true, onClick: (() => onDeleteClick(record)) });
    return a;
};
const sharedOnCell = data => isAsyncFile(data) ? { colSpan: 0 } : {};
const columns = ({ type, onDeleteClick, permissionsFn, showAuthorName }) => {
    const colsCount = type == 1 ? 7 : 6;
    const cols = [
        {
            title: "Nome",
            dataIndex: "name",
            width: "35%",
            sorter: (a, b) => sorterWrapper(a, b, (_a, _b) => _a.name.localeCompare(_b.name)),
            render: (text, record) => isAsyncFile(record) ? (_jsx(LoadingFileRow, { asyncFile: record })) : (_jsxs(_Fragment, { children: [_jsxs("div", { className: "d-flex align-center", children: [fileIcon(record.content_type), _jsx("p", { className: `mt-0 mb-0 ml-2 ${textEllipsis1}`, children: text })] }), record.trip &&
                        _jsxs("div", { className: "ml-6 mt-1 d-flex align-center", children: [_jsxs("span", { className: cx(idLabelStyle, "type-body-4"), children: ["#", record.trip.id] }), _jsx("span", { className: `type-body-5 ml-2 ${textEllipsis1}`, style: { color: theme["neutral-50"] }, children: record.trip.name })] })] })),
            onCell: data => isAsyncFile(data) ? { colSpan: colsCount } : { colSpan: 1 }
        },
        {
            title: "Autor",
            dataIndex: "owner",
            render: (owner, record) => {
                if (isAsyncFile(record))
                    return null;
                const avatarProps = !showAuthorName ? { showTooltip: true } : {};
                return (_jsx(AvatarText.User, { user: owner, secondaryText: null, ...avatarProps }));
            },
            onCell: sharedOnCell
        },
        {
            title: "Extensão",
            dataIndex: "extension",
            sorter: (a, b) => sorterWrapper(a, b, (_a, _b) => _a.extension.localeCompare(_b.extension)),
            render: (text, record) => !isAsyncFile(record) && text.toUpperCase(),
            onCell: sharedOnCell,
            responsive: ["xxl"]
        },
        {
            title: "Tamanho",
            dataIndex: "size",
            sorter: (a, b) => sorterWrapper(a, b, (_a, _b) => _a.size - _b.size),
            render: (text, record) => !isAsyncFile(record) && formatBytes(text),
            onCell: sharedOnCell,
            responsive: ["xl"]
        },
        {
            title: "Data",
            dataIndex: "updated_date",
            defaultSortOrder: "descend",
            className: noWrap,
            sorter: (a, b) => sorterWrapper(a, b, (_a, _b) => _a.updated_date.getTime() - _b.updated_date.getTime()),
            render: (text, record) => !isAsyncFile(record) && format(text, "dd/MM/yyyy"),
            onCell: sharedOnCell
        }
    ];
    if (type === 1) {
        cols.splice(2, 0, {
            title: "Vértices",
            dataIndex: "complexity",
            sorter: (a, b) => sorterWrapper(a, b, (_a, _b) => _a.complexity - _b.complexity),
            render: (text, record) => !isAsyncFile(record) && text && (!isNaN(text) ? numberFormatter.format(text) : '-'),
            onCell: sharedOnCell
        });
        cols.splice(3, 0, {
            title: "Marcadores",
            dataIndex: "placemarks",
            sorter: (a, b) => sorterWrapper(a, b, (_a, _b) => _a.placemarks - _b.placemarks),
            render: (text, record) => !isAsyncFile(record) && text && (!isNaN(text) ? numberFormatter.format(text) : '-'),
            onCell: sharedOnCell
        });
    }
    if (permissionsFn.DOWNLOAD_FILE())
        cols.push({
            key: "actions",
            width: "60px",
            render: record => !isAsyncFile(record) && (_jsx(Dropdown, { trigger: ['click'], menu: { items: actions(record, { onDeleteClick, permissionsFn }) }, children: _jsx(Button, { type: "text", shape: "circle", icon: _jsx(SvgIcon, { name: "ic_options" }), className: "button-icon" }) })),
            onCell: sharedOnCell
        });
    return cols;
};
export const AttachmentsTable = ({ project, type, tabActionRef, fileId, getProjectData }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filesLoading, setFilesLoading] = useState([]);
    const permissions = usePermissions(["UPLOAD_FILE"], { project });
    const permissionsFn = usePermissionsFn(["DOWNLOAD_FILE", "DELETE_FILE"]);
    const { notification } = App.useApp();
    const { eventEmitter } = useEvents();
    const screens = Grid.useBreakpoint();
    const showAuthorName = useMemo(() => getResponsiveValue(screens, { xxl: true }, false), [screens]);
    eventEmitter.FILE_PROCESSING.useSubscription((event) => {
        setFilesLoading(prev => prev.filter(e => e.filename !== event["file"]["name"]));
        if (event["success"]) {
            getData();
            getProjectData();
        }
    });
    const getData = useCallback(() => {
        setLoading(true);
        ProjectManagementService.getProjectAttachments(project.id, { type: type, trip: true })
            .then(setData)
            .finally(() => setLoading(false));
    }, [project.id, type]);
    const deleteModal = useModal("confirmation-modal");
    const onDeleteClick = (attachment) => {
        deleteModal.show({
            sentence: "Tem a certeza que quer apagar este ficheiro?"
        })
            .then(() => ProjectManagementService.deleteAttachment(attachment.id))
            .then(getData).then(getProjectData)
            .then(() => notification.success({ message: 'Ficheiro apagado', ...globalNotificationConfig }))
            .catch(() => notification.error({ message: 'Erro ao apagar o ficheiro', ...globalNotificationConfig }));
    };
    const attachmentUploadModal = useModal("attachment-upload-modal");
    const onAddClick = useCallback(() => {
        attachmentUploadModal.show({ type, projectId: project.id })
            .then((data) => {
            if (data["type"] === 1)
                setFilesLoading(prev => ([...prev, data]));
            else {
                getData();
                getProjectData();
            }
        });
    }, [attachmentUploadModal, getData, getProjectData, project.id, type]);
    useEffect(getData, [getData]);
    if (loading)
        return _jsx(Loading, {});
    if (!data)
        return _jsx("div", { children: "Error" });
    let _data = data;
    if (filesLoading.length > 0)
        _data = [
            ...(filesLoading.map((e, i) => ({ id: `ASYNC_${e.filename}_${i}`, filename: e.filename, async: true }))),
            ...data
        ];
    return (_jsxs(_Fragment, { children: [tabActionRef.current && permissions.UPLOAD_FILE && createPortal(_data.length > 0 && (_jsx(Button, { icon: _jsx(SvgIcon, { name: "ic_plus", size: 17 }), type: "primary", onClick: onAddClick, children: "Adicionar" })), tabActionRef.current), _data.length === 0 ? (_jsx(EmptyContent, { button: permissions.UPLOAD_FILE ? { content: "Adicionar", onClick: onAddClick } : undefined, className: "py-16 mb-6", description: type === 0 ? "Aqui pode consultar ficheiros de texto, som, imagem ou vídeo" : "Aqui pode consultar ficheiros KML, KMZ e ZIP (com shapefiles ou ficheiros 3D)" })) : (_jsx(Table, { dataSource: _data, columns: columns({ type, onDeleteClick, permissionsFn, showAuthorName }), rowKey: "id", rowClassName: record => (fileId && record.id === fileId) && highlightRow, showSorterTooltip: false, sortDirections: ["ascend", "descend", "ascend"], pagination: { hideOnSinglePage: true } }))] }));
};
