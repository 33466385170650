import { css } from "@emotion/css";
export default css({
    "& .ant-modal-content .ant-modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        "& .ant-btn": {
            width: 206
        }
    },
});
