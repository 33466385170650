import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { Modal } from "antd";
import { useCallback } from "react";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { Button } from "~/styles";
import { defaultModalProps } from "~/utils";
import style from "./style";
export const TripPointAddModal = createModal(({ project }) => {
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const tripPointEditModal = useModal('trip-point-edit-modal');
    const attachmentUploadModal = useModal("attachment-upload-modal");
    const modalProps = {
        ...defaultProps,
        open: modal.visible,
        footer: null,
        title: "Adicionar Pontos de visita",
        closeIcon: _jsx(SvgIcon, { name: "ic_close", size: 28, color: theme["neutral-100"] }),
    };
    const onManualClick = useCallback(() => tripPointEditModal.show({ project, catchExpection: true })
        .then(defaultProps.onOk)
        .catch(modal.hide), [project, tripPointEditModal]);
    const onFileClick = useCallback(() => attachmentUploadModal.show({ type: 1, projectId: project.id, isTripPoint: true, catchExpection: true })
        .then(defaultProps.onOk)
        .catch(modal.hide), [attachmentUploadModal]);
    return (_jsxs(Modal, { width: 480, className: style, ...modalProps, children: [_jsx("div", { className: "type-body-5 pb-6", style: { color: theme["neutral-50"] }, children: "Selecione a op\u00E7\u00E3o pretendida para adicionar pontos de visita." }), _jsxs("div", { className: "mt-6", children: [_jsx(Button, { className: "mr-4", icon: _jsx(SvgIcon, { name: "ic_place_locator" }), onClick: onManualClick, children: "Manualmente" }), _jsx(Button, { type: "primary", icon: _jsx(SvgIcon, { name: "ic_files" }), onClick: onFileClick, children: "Ficheiro" })] })] }));
});
