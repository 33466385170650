import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { Input } from "antd";
import CoordinateParser from "coordinate-parser";
import { useContext, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { MapContext, MapControl, createElement } from "~/utils";
const errorStyle = css({
    display: "flex",
    alignItems: "center",
    borderRadius: 6,
    background: "rgba(255, 255, 255, 0.50)",
    marginTop: 6,
    color: "#CF0000",
    padding: 4
});
const style = css({
    width: 415,
    backgroundColor: theme["neutral-0"],
    display: "flex",
    flexDirection: "column",
    gap: 18,
    borderRadius: 8,
    boxShadow: "0px 0px 40px rgba(30, 30, 30, 0.1)",
    "& .ant-input-prefix": {
        marginInlineEnd: 12,
        fontSize: 18
    },
    "& .ant-input-suffix": {
        marginInlineStart: 12,
        fontSize: 18
    },
    "& .ant-input-clear-icon": {
        display: "inline-flex",
        color: "inherit",
        fontSize: "inherit"
    }
});
const Control = (props) => {
    const [value, setValue] = useState("");
    const [status, setStatus] = useState("");
    const onPressEnter = (event) => {
        const value = event.target.value;
        try {
            const parser = new CoordinateParser(value);
            const lat = parser.getLatitude();
            const lon = parser.getLongitude();
            props.onPressEnter([lat, lon]);
            setValue("");
        }
        catch (error) {
            setStatus("error");
        }
    };
    const onChange = (e) => {
        setValue(e.target.value);
        setStatus("");
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: style, children: _jsx(Input, { id: "coordinate", placeholder: "Latitude, Longitude", prefix: _jsx(SvgIcon, { name: "ic_search" }), allowClear: { clearIcon: _jsx(SvgIcon, { name: "ic_delete" }) }, value: value, onChange: onChange, onPressEnter: onPressEnter, status: status }) }), status &&
                _jsxs("div", { className: errorStyle, children: [_jsx(SvgIcon, { name: "ic_close" }), _jsx("div", { className: "ml-1 type-body-5", children: "Por favor introduza coordenadas v\u00E1lidas" })] })] }));
};
export const CoordinateControl = (props) => {
    const map = useContext(MapContext);
    const container = useMemo(() => createElement("div", "nmapsgl-ctrl"), []);
    const control = useMemo(() => new MapControl({ container }), [container]);
    useEffect(() => {
        if (!map.hasControl(control))
            map.addControl(control, "top-left");
        return () => {
            if (map.hasControl(control))
                map.removeControl(control);
        };
    }, [control, map]);
    return createPortal(_jsx(Control, { ...props }), container);
};
