import axios from "axios";
import config from "~/config";
const client = axios.create({
    baseURL: config.services.nmaps.baseUrl,
    headers: { "Content-type": "application/json" },
    paramsSerializer: { indexes: null },
    params: {
        access_token: config.services.nmaps.accessToken
    }
});
export function genericSearch(body) {
    body.search_text += " portugal";
    return client.post("/v1/search/generic_search", body)
        .then(r => r.data.results);
}
export function reverseGeocode(body) {
    return client.post("/v1/search/reverse_geocode", body)
        .then(r => r.data.results);
}
