import { css } from "@emotion/css";
import { theme } from "~/config";
export const fileUploadStyle = (isTripPoint) => css({
    display: "flex",
    minHeight: isTripPoint ? 'auto' : 175,
    marginBottom: "0!important",
    ".ant-row.ant-form-item-row": {
        flex: 1,
        ".ant-form-item-control-input": {
            flex: 1,
            flexFlow: "column",
            ".ant-form-item-control-input-content": {
                display: "flex",
                width: "100%"
            }
        }
    },
    ".ant-upload-wrapper": {
        flex: 1,
        display: "flex",
        flexFlow: "column",
        ".ant-upload.ant-upload-select": {
            flex: 1,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme["neutral-0"],
            border: `1px dashed ${theme["neutral-30"]}`,
            borderRadius: 6,
            "&:hover .label, &:hover .icon": {
                color: theme["primary-100"]
            },
            ".ant-upload": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column"
            }
        },
        ".ant-upload-list": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            ".ant-upload-list-item-container": {
                flex: 1
            },
            ".ant-upload-list-item": {
                width: "100%",
                height: 64,
                backgroundColor: theme["primary-10"],
                borderRadius: 6,
                padding: 15,
                "&.ant-upload-list-item-uploading": {
                    height: 64,
                    alignItems: "flex-start",
                    ".ant-upload-list-item-actions": {
                        display: "none"
                    }
                },
                ".ant-upload-list-item-name": {
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: "18px",
                    color: theme["neutral-100"]
                },
                ".ant-upload-list-item-actions button": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: "1 !important"
                },
                ".ant-upload-list-item-progress": {
                    width: "calc(100% - 25px)",
                    bottom: 5,
                    ".ant-progress-text": {
                        fontSize: 14
                    }
                }
            }
        },
        ".ant-upload-icon": {
            display: "flex"
        },
        ".ant-upload.ant-upload-btn": {
            padding: "56px 0",
            ".icon": {
                marginBottom: 6,
                fontSize: 22,
                color: theme["neutral-50"]
            },
            ".label": {
                color: theme["neutral-50"],
                marginTop: 0,
                marginBottom: 6
            }
        }
    }
});
export const uploadRulesStyle = css({
    "&.ant-tooltip": {
        "--antd-arrow-background-color": theme["tooltip-75"],
        ".ant-tooltip-inner": {
            maxWidth: 253,
            padding: 10,
            textAlign: "start",
            fontSize: 12,
            lineHeight: 1.33,
            backgroundColor: theme["tooltip-75"],
            "ol": {
                listStylePosition: "inside",
                paddingInlineStart: 0,
                margin: 0,
                "& > li:not(:last-child)": {
                    marginBottom: 15
                },
                "ul": {
                    paddingInlineStart: 22,
                    listStylePosition: "outside",
                    listStyleType: "disc",
                    marginTop: 5
                }
            },
        }
    }
});
export const fileUploadEmptyStyle = css({
    ".ant-upload-list.ant-upload-list-text": {
        display: "none"
    }
});
export default css({
    ".ant-modal-content .ant-modal-body": {
        display: "flex",
        flexFlow: "column",
        height: "calc(100vh - 300px)",
        maxHeight: 510,
        overflowY: "scroll"
    },
    ".ant-form": {
        display: "flex",
        flexFlow: "column",
        flex: 1
    }
});
