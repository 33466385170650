import { css } from "@emotion/css";
import { theme } from "~/config";
export const customScroll = {
    overflowY: "scroll",
    "@supports (overflow-y: overlay)": {
        overflowY: "overlay"
    },
    "&::-webkit-scrollbar": {
        width: 12,
    },
    "&::-webkit-scrollbar-thumb": {
        height: 6,
        border: "3px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
        WebkitBorderRadius: 12,
        backgroundColor: "rgba(0, 0, 0, 0.15)",
        WebkitBoxShadow: "inset 0 0 0 1px rgba(0, 0, 0, 0.025)"
    },
    "&::-webkit-scrollbar-button": {
        display: "none",
    },
    "&::-webkit-scrollbar-corner": {
        backgroundColor: "transparent"
    }
};
export const marginX = (value) => ({
    marginLeft: value,
    marginR: value
});
export const marginY = (value) => ({
    marginTop: value,
    marginBottom: value
});
export const paddingX = (value) => ({
    paddingLeft: value,
    paddingRight: value
});
export const paddingY = (value) => ({
    paddingTop: value,
    paddingBottom: value
});
export const padding = (valueY, valueX) => ({
    paddingTop: valueY,
    paddingBottom: valueY,
    paddingLeft: valueX,
    paddingRight: valueX
});
export const textEllipsis = (lineClamp) => ({
    whiteSpace: 'nowrap',
    textOverflow: "ellipsis",
    overflow: 'hidden',
    overflowWrap: 'break-word',
    ...(lineClamp === 1 && { wordBreak: 'break-all' }),
    [`@supports (-webkit-line-clamp: ${lineClamp})`]: {
        display: "-webkit-box",
        whiteSpace: "initial",
        WebkitLineClamp: lineClamp,
        WebkitBoxOrient: "vertical"
    },
});
export const noWrap = css({
    whiteSpace: "nowrap"
});
export const textEllipsis1 = css(textEllipsis(1));
export const textEllipsis2 = css(textEllipsis(2));
export const circle = css({
    width: "var(--size, 24px)",
    height: "var(--size, 24px)",
    minWidth: "var(--size, 24px)",
    fontSize: 12,
    fontWeight: 500,
    border: `2px solid ${theme["neutral-0"]}`,
    backgroundColor: theme["primary-100"],
    color: theme["neutral-0"],
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 8
});
export const highlightRow = css({
    animation: "fadeout 3s ease-in-out",
    "@-webkit-keyframes fadeout": {
        "0%": {
            backgroundColor: theme["primary-20"]
        },
        "50%": {
            backgroundColor: theme["primary-20"]
        },
        "100%": {
            backgroundColor: theme["neutral-0"]
        }
    }
});
export const idCollumnStyle = css({
    background: theme["neutral-20"],
    color: theme["neutral-50"],
    padding: 6,
    borderRadius: 4
});
export const idLabelStyle = css({
    background: theme["neutral-20"],
    color: theme["neutral-50"],
    padding: "2px 4px",
    borderRadius: 2
});
