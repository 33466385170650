import { css } from "@emotion/css";
import config from "~/config";
export const uploadCropStyle = css({
    ".ant-upload-select": {
        cursor: 'pointer',
    },
    ".ant-upload-drag-icon": {
        height: 40,
        width: 40,
        backgroundColor: config.theme['neutral-10'],
        borderRadius: '50%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    ".ant-upload-list.ant-upload-list-picture .ant-upload-list-item": {
        padding: 0,
        border: 0,
        height: 40,
        margin: 0,
        ".ant-upload-list-item-name": {
            color: config.theme['neutral-100'],
        },
        ".ant-upload-list-item-thumbnail": {
            height: 40,
            width: 40,
            borderRadius: "50%"
        },
    }
});
export const userModalStyle = css({
    ".ant-radio-wrapper-in-form-item": {
        marginInlineEnd: 100
    },
    ".ant-modal-content .ant-modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        ".ant-btn": {
            width: 206
        }
    }
});
export default css({
    backgroundColor: config.theme['neutral-0'],
    borderRadius: 16,
    padding: 24,
    width: '100%',
    display: "flex",
    flexFlow: "column",
    height: "100%",
    ".ant-table-wrapper": {
        overflowX: "auto"
    },
    ".ant-table-empty .ant-table-cell": {
        border: "none"
    },
    "& .ren-loading": {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    "& .ant-pagination-total-text": {
        position: "absolute",
        right: 0
    },
    ".avatar-text p": {
        maxWidth: "unset"
    }
});
