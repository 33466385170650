import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { cx } from "@emotion/css";
import { App, Checkbox, Form, Modal, Select, Space, Upload, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { ProjectManagementService } from "~/services";
import { Button, FormItem } from "~/styles";
import { defaultModalProps, globalNotificationConfig, usePermissionsFn } from "~/utils";
import style, { fileUploadEmptyStyle, fileUploadStyle, uploadRulesStyle } from "./style";
const attachmentExtensions = {
    "0": "*",
    "1": ".kml,.kmz,.zip"
};
const normFile = e => Array.isArray(e) ? e : e?.fileList;
const UploadRules = (_jsxs("ol", { children: [_jsxs("li", { children: ["Ao selecionar Ficheiros GEO s\u00E3o aceites os seguintes formatos:", _jsxs("ul", { children: [_jsx("li", { children: "ZIP de Shapefiles (contendo no m\u00EDnimo prj, shp e shx e todos com o mesmo nome)" }), _jsx("li", { children: "ZIP 3D (contendo no m\u00E1ximo .obj, .mtl e .gr.json e todos com o mesmo nome)" }), _jsx("li", { children: "KMZ (contendo .kml)" }), _jsx("li", { children: "KML" })] })] }), _jsxs("li", { children: ["Ap\u00F3s upload, estes formatos ser\u00E3o validos e processados da seguinte forma:", _jsxs("ul", { children: [_jsx("li", { children: "o ZIP de Shapefiles ser\u00E1 convertido em .kml" }), _jsx("li", { children: "os ZIP 3D e KML ser\u00E3o somente validados" }), _jsx("li", { children: "nos KMZ s\u00E3o extraidos somente os .kml" }), _jsx("li", { children: "em todos os ficheiros ser\u00E1 feita a contagem dos v\u00E9rtices" })] })] }), _jsxs("li", { children: ["Aceita\u00E7\u00E3o", _jsxs("ul", { children: [_jsx("li", { children: "cumprir regras do ponto 1" }), _jsx("li", { children: "passar valida\u00E7\u00E3o e processamento do ponto 2 com sucesso" }), _jsx("li", { children: "qualquer ficheiro abaixo dos 100 MB, abaixo de 750K v\u00E9rtices e abaixo dos 2000 placemarks." })] })] })] }));
export const AttachmentUploadModal = createModal(({ type, projectId, isTripPoint, catchExpection }) => {
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const { notification } = App.useApp();
    const confirmationModal = useModal("confirmation-modal");
    const [createTrips, setCreateTrips] = useState(false);
    const navigate = useNavigate();
    const initialState = {
        type,
        projectId,
        trip: undefined,
        fileList: [],
    };
    const [onAdd, setOnAdd] = useState(false);
    const [disableButton, setDisableButton] = useState(!(type !== undefined && projectId));
    const [projectOptions, setProjectOptions] = useState([]);
    const [tripOptions, setTripOptions] = useState([]);
    const filepath = useRef("");
    const [form] = useForm();
    const permissionsFn = usePermissionsFn(["UPLOAD_FILE"]);
    const { user } = useAuthenticator(context => [context.user]);
    const userGroups = user.getSignInUserSession()?.getAccessToken().payload["cognito:groups"];
    useEffect(() => {
        const members = [];
        if (user.attributes?.sub && userGroups.includes("Interno")) {
            members.push(user.attributes?.sub);
        }
        ProjectManagementService.getProjectsShortInfo({ members })
            .then((projects) => {
            setProjectOptions(projects.map(p => ({ 'label': p.name, 'value': p.id })));
            if (projectId) {
                ProjectManagementService.getTripsShortInfo({ project_id: projectId })
                    .then(trips => setTripOptions(trips.map(t => ({ 'label': t.name, 'value': t.id }))));
            }
        });
    }, [projectId, user.attributes?.sub]);
    const onValuesChange = (changedValues) => {
        if ('projectId' in changedValues) {
            ProjectManagementService.getTripsShortInfo({ project_id: changedValues.projectId })
                .then((trips) => {
                setTripOptions(trips.map((t) => { return { 'label': t.name, 'value': t.id }; }));
            });
        }
        const condition = !onAdd && !form.getFieldValue("projectId") || onAdd && (form.getFieldValue("fileList").length == 0 || form.getFieldValue("fileList").length && form.getFieldValue("fileList")[0].status !== "done");
        setDisableButton(condition);
    };
    const beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('O ficheiro deve ter no máximo 2GB');
            return Upload.LIST_IGNORE;
        }
    };
    const customRequest = (componentsData) => {
        const data = {
            filename: componentsData.file.name,
            content_type: componentsData.file.type || "application/octet-stream",
            type: form.getFieldValue("type"),
        };
        if (data.content_type === "text/plain")
            data.content_type += "; charset=utf-8";
        if (form.getFieldValue("trip"))
            data['trip_id'] = form.getFieldValue("trip");
        else
            data['project_id'] = form.getFieldValue("projectId");
        const uploadFile = e => componentsData.onProgress({ percent: (e.loaded / e.total) * 100 });
        ProjectManagementService.getPresignedPost(data)
            .then((presignedPostData) => {
            filepath.current = presignedPostData.fields.key;
            ProjectManagementService.uploadFile(presignedPostData, componentsData.file, uploadFile).then(componentsData.onSuccess);
        })
            .catch(() => {
            componentsData.onError("Error uploading file");
        });
    };
    const nextStep = () => {
        setOnAdd(true);
        setDisableButton(true);
    };
    const addAttachment = () => {
        const data = {
            "filepath": filepath.current
        };
        if (isTripPoint) {
            data['project_id'] = form.getFieldValue("projectId");
            data['visit'] = createTrips ? "true" : "false";
        }
        else {
            data["type"] = form.getFieldValue("type");
            data["author"] = user.attributes?.sub;
            if (form.getFieldValue("trip"))
                data['trip_id'] = form.getFieldValue("trip");
            else
                data['project_id'] = form.getFieldValue("projectId");
        }
        let _notification;
        if (isTripPoint) {
            if (createTrips) {
                _notification = {
                    type: "success",
                    style: { width: 395 },
                    message: (_jsxs("div", { children: [_jsx("span", { className: "my-0 mr-3", children: "Visitas de campo criadas" }), _jsx("a", { onClick: () => navigate(`/projects/${projectId}/trips`), children: "Ver visitas de campo" })] }))
                };
            }
            else {
                _notification = {
                    type: "success",
                    message: "Pontos de visita criados com sucesso"
                };
            }
        }
        else if (data["type"] === 1) {
            _notification = {
                key: 'FILE_PROCESSING',
                type: "info",
                icon: _jsx(LoadingOutlined, { style: { color: theme["primary-6"], fontWeight: "bold" } }),
                message: "Os ficheiros estão a ser processados"
            };
        }
        else {
            _notification = {
                type: "success",
                message: "Ficheiro adicionado com sucesso"
            };
        }
        const promisse = isTripPoint ? ProjectManagementService.bulkTripPoints : ProjectManagementService.processFile;
        const requestData = isTripPoint ? { ...data, filepath: null, filename: form.getFieldValue("fileList")[0].name } : data;
        promisse(requestData)
            .then(() => defaultProps.onOk({
            ...data,
            filename: form.getFieldValue("fileList")[0].name,
            content_type: form.getFieldValue("fileList")[0].type
        }))
            .then(() => notification.open({
            ...globalNotificationConfig,
            ..._notification
        }))
            .catch(() => {
            notification.error({ message: "Erro a criar pontos de visita.", ...globalNotificationConfig });
        });
    };
    const onCancelClick = () => {
        confirmationModal.show({
            sentence: "Tem a certeza que quer descartar as alterações?",
            cancelLabel: 'Não', confirmationLabel: 'Sim'
        }).then(() => {
            if (catchExpection)
                modal.reject();
            modal.hide();
        });
    };
    const modalProps = {
        ...defaultProps,
        closeIcon: _jsx(SvgIcon, { name: "ic_close", size: 28, color: theme["neutral-100"] }),
        onCancel: onCancelClick,
    };
    const typeOptions = useMemo(() => {
        const r = [{ value: 0, label: 'Anexo' }];
        if (isTripPoint)
            r.push({ value: 1, label: 'Ficheiro KML' });
        else if (permissionsFn.UPLOAD_FILE)
            r.push({ value: 1, label: 'Ficheiro GEO' });
        return r;
    }, [permissionsFn]);
    if (typeOptions.length === 1)
        initialState.type = typeOptions[0].value;
    const onChange = (e) => {
        setCreateTrips(e.target.checked);
    };
    return (_jsx(Modal, { ...modalProps, className: style, width: 494, title: "Adicionar ficheiro", footer: _jsxs(Space, { size: 24, className: "full-width", children: [_jsx(Button, { onClick: () => onAdd ? (setOnAdd(false), form.setFieldValue("fileList", [])) : onCancelClick(), children: onAdd ? 'Voltar' : 'Cancelar' }), _jsx(Button, { type: "primary", onClick: () => onAdd ? addAttachment() : nextStep(), disabled: disableButton, children: onAdd ? 'Adicionar' : 'Seguinte' })] }), children: _jsxs(Form, { form: form, layout: "vertical", requiredMark: false, preserve: false, initialValues: initialState, onValuesChange: onValuesChange, children: [_jsx(FormItem, { name: "type", wrapperCol: { span: 24 }, label: "Tipo de ficheiro", tooltip: { title: UploadRules, icon: _jsx(InfoCircleOutlined, {}), overlayClassName: uploadRulesStyle }, children: _jsx(Select, { options: typeOptions, placeholder: "Escolher tipo de ficheiro", disabled: type !== undefined || onAdd }) }), _jsx(FormItem, { className: "mb-0", shouldUpdate: true, children: ({ getFieldValue }) => _jsx(FormItem, { label: "Atribuir a projeto", name: "projectId", wrapperCol: { span: 24 }, children: _jsx(Select, { showSearch: true, placeholder: "Selecionar projeto", optionFilterProp: "children", options: projectOptions, disabled: !!projectId || getFieldValue("type") == undefined || onAdd, filterOption: (input, option) => (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase()) }) }) }), _jsx(FormItem, { className: "mb-0", shouldUpdate: true, children: ({ getFieldValue }) => getFieldValue("type") == 0 &&
                        _jsx(FormItem, { label: "Atribuir a visita de campo", name: "trip", wrapperCol: { span: 24 }, children: _jsx(Select, { showSearch: true, placeholder: "Selecionar visita de campo", optionFilterProp: "children", options: tripOptions, disabled: getFieldValue("projectId") == undefined || onAdd, filterOption: (input, option) => (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase()) }) }) }), _jsx(FormItem, { className: "mb-0", shouldUpdate: true, children: !onAdd ? null :
                        ({ getFieldValue }) => _jsx(FormItem, { name: "fileList", className: cx(fileUploadStyle(getFieldValue("fileList").length != 0 && isTripPoint), { [fileUploadEmptyStyle]: getFieldValue("fileList").length == 0 }), valuePropName: "fileList", wrapperCol: { span: 24 }, getValueFromEvent: normFile, children: _jsx(Upload, { showUploadList: { "removeIcon": _jsx(SvgIcon, { name: "ic_close" }) }, iconRender: () => { return _jsx(SvgIcon, { name: "ic_files" }); }, beforeUpload: beforeUpload, maxCount: 1, fileList: getFieldValue("fileList"), customRequest: customRequest, progress: {
                                    format: percent => percent && `${parseFloat(percent.toFixed(0))}%`
                                }, accept: isTripPoint ? '.kml' : attachmentExtensions[getFieldValue("type")], children: getFieldValue("fileList").length == 0 &&
                                    _jsxs(_Fragment, { children: [_jsx(SvgIcon, { name: "ic_files", className: "icon" }), _jsxs("p", { className: "type-body-1 my-2 label", children: [_jsx("u", { className: "type-body-2", children: "Clique para upload" }), " ou Arraste e largue um ficheiro"] }), _jsx("p", { className: "type-body-5 my-0", style: { color: theme["neutral-40"] }, children: "Tamanho m\u00E1ximo: 2 GB" })] }) }) }) }), isTripPoint && form.getFieldValue("fileList") && form.getFieldValue("fileList").length !== 0 &&
                    _jsxs("div", { className: "my-3", children: [_jsx("div", { className: "type-body-5 mb-6", style: { color: theme["neutral-50"] }, children: "Este ficheiro ir\u00E1 listar os pontos de visita." }), _jsx(Checkbox, { checked: createTrips, onChange: onChange, children: _jsx("div", { className: "type-body-5", children: "Quero popular, automaticamente, visitas de campo por cada ponto de visita no seu projeto." }) })] })] }) }));
});
