import { register } from "@ebay/nice-modal-react";
import { Projects, Users } from "~/modules";
import { AttachmentUploadModal } from "./attachment-upload-modal";
import { ConfirmationModal } from "./confirmation-modal";
export { default as AvatarText, UserAvatarGroup } from "./avatar";
export { EmptyContent } from "./empty-content";
export { ErrorFallback } from "./error-fallback";
export { Header } from "./header";
export { InfoTooltip, InfoTooltipContext, InfoTooltipProvider } from "./info-tooltip";
export { Loading } from "./loading";
export { MembersSelection } from "./members-selection";
export { ProjectAvatar } from "./project-avatar";
export { ProjectCard } from "./project-card";
export * as StatusTag from "./status-tag";
export { TripAvatar } from "./trip-avatar";
export const registerModals = () => {
    register('confirmation-modal', ConfirmationModal);
    register('attachment-upload-modal', AttachmentUploadModal);
    Projects.registerModals();
    Users.registerModals();
};
