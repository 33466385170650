import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { Layout } from "antd";
import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { Header } from '~/components';
import { theme } from "~/config";
import { SvgIcon } from '~/icons';
import { Button } from "~/styles";
const style = {
    header: css({
        "&.ant-layout-header": {
            padding: 0,
            backgroundColor: theme["neutral-10"],
            height: 40,
            lineHeight: "116px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "38px 52px",
        },
        "& .header-title": {
            display: "flex",
            alignItems: "center",
            minWidth: 250,
            marginRight: 12
        }
    }),
    content: css({
        margin: "0 52px 40px 52px"
    })
};
export const MainLayout = () => {
    const matches = useMatches();
    const navigate = useNavigate();
    const { title, goBack } = (matches.at(-1)?.handle || {});
    return (_jsxs(_Fragment, { children: [_jsxs(Layout.Header, { className: style.header, children: [_jsxs("div", { className: "header-title", children: [goBack &&
                                _jsx(Button, { type: "text", shape: "circle", icon: _jsx(SvgIcon, { name: 'ic_back', size: 22 }), onClick: () => navigate(-1), className: "mr-3 type-heading-7" }), _jsx("h1", { className: `type-heading-${goBack ? "7" : "1"} mb-0 mt-0`, children: title || "" })] }), _jsx(Header, {})] }), _jsx(Layout.Content, { className: style.content, children: _jsx(Outlet, {}) })] }));
};
