import { css } from "@emotion/css";
import { theme } from "~/config";
const hiddenStyle = {
    visibility: "hidden",
    "& *": {
        opacity: 0
    }
};
export default (hidden, editMode) => css({
    ...(hidden && hiddenStyle),
    "& .ant-modal-content .ant-modal-footer": {
        display: "flex",
        justifyContent: editMode ? "space-between" : "flex-end",
        "& .ant-btn": {
            width: 206
        }
    },
    "& .ant-upload-wrapper .ant-upload-list .ant-upload-list-item": {
        marginTop: 0
    },
    ".project-image": {
        ".ant-upload-select": {
            width: "100%",
            padding: 10,
            cursor: "pointer",
            borderRadius: 6,
            backgroundColor: theme["neutral-0"],
            border: `1px solid ${theme["neutral-30"]}`
        },
        ".ant-upload-drag-icon": {
            height: 60,
            width: 60,
            padding: 22,
            backgroundColor: theme["primary-20"],
            border: `1px dashed ${theme["primary-100"]}`,
            borderRadius: 3
        }
    }
});
