import { theme } from "~/config";
export const amplifyGlobalTheme = {
    ":root": {
        "--amplify-fonts-default-variable": theme["font-family"],
        "--amplify-line-heights-medium": theme["base-line-height"] + "px",
        "--amplify-components-authenticator-router-border-width": 0,
        "--amplify-components-authenticator-router-box-shadow": "none",
        "--amplify-components-authenticator-form-padding": 0,
        "--amplify-components-authenticator-container-width-max": "350px",
        "--amplify-components-field-label-color": theme["neutral-100"],
        "--amplify-components-fieldcontrol-border-color": theme["neutral-20"],
        "--amplify-components-fieldcontrol-focus-border-color": theme["primary-80"],
        "--amplify-components-fieldcontrol-focus-box-shadow": "0 0 0 2px rgba(5, 135, 255, 0.1)",
        "--amplify-components-button-primary-background-color": theme["primary-100"],
        "--amplify-components-button-primary-hover-background-color": theme["primary-120"],
        "--amplify-components-button-primary-focus-background-color": theme["primary-120"],
        "--amplify-components-button-hover-border-color": theme["primary-80"],
        "--amplify-components-button-link-color": theme["primary-100"],
        "--amplify-components-button-link-hover-color": theme["primary-120"],
        "--amplify-components-button-link-hover-background-color": "none",
        "--amplify-components-button-font-weight": 500,
        "--amplify-colors-brand-primary-10": theme["primary-10"],
        "--amplify-colors-brand-primary-20": theme["primary-20"],
        "--amplify-colors-brand-primary-40": theme["primary-40"],
        "--amplify-colors-brand-primary-100": theme["primary-100"],
    },
    ".amplify-highlightmatch__highlighted": {
        color: theme["primary-100"]
    }
};
