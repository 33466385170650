import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from "antd";
import { createStyles } from "antd-style";
import { formatDistance } from "date-fns";
import { theme } from "~/config";
const useStyles = createStyles(({ css }, props) => {
    return css({
        display: "flex",
        alignItems: "center",
        paddingLeft: 16,
        paddingRight: 16,
        ...(!props.read && { backgroundColor: theme["primary-20"] }),
        ".item-container": {
            paddingTop: 16,
            paddingBottom: 16,
            display: "flex",
            flex: 1,
            fontSize: 14,
            lineHeight: 1.28,
            ".ant-avatar": {
                flexShrink: 0,
                marginRight: 12
            },
            ".author-name": {
                fontWeight: 600
            },
            ".time": {
                color: theme["neutral-50"],
                fontSize: 12
            }
        },
        "&:not(:last-child) .item-container": {
            borderBottom: `1px solid ${theme["neutral-10"]}`,
        },
    });
});
export const NotificationItem = ({ notification }) => {
    const { styles } = useStyles({ read: notification.read });
    return (_jsx("li", { className: styles, children: _jsxs("div", { className: "item-container", children: [_jsx(Avatar, { src: notification.owner.picture, size: 36, icon: _jsx(UserOutlined, {}) }), _jsxs("div", { className: "d-block flex-fill", children: [_jsxs("div", { className: "d-flex justify-space-between align-center mb-1", children: [_jsx("div", { className: "author-name", children: notification.owner.name || "Utilizador não atribuído" }), _jsx("div", { className: "time", children: formatDistance(notification.created_date, Date.now()) })] }), _jsx("div", { className: "message", children: notification.message })] })] }) }));
};
