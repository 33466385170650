import styled from "@emotion/styled";
// eslint-disable-next-line no-restricted-imports
import { Button as AntdButton, Form, theme as antdTheme } from "antd";
import { theme } from "~/config";
import { customScroll, paddingX } from "./utils";
const seedToken = {
    colorPrimary: theme['primary-100']
};
const mapToken = antdTheme.defaultAlgorithm({ ...antdTheme.defaultSeed, ...seedToken });
// TODO: check const t = antdTheme.getDesignToken();
export const antdThemeConfig = {
    token: {
        ...seedToken,
        fontFamily: 'Roboto',
        fontSize: theme['base-font-size'],
        colorLink: theme['primary-100']
    },
    components: {
        Layout: {
            colorBgHeader: theme["dark-blue-100"],
            colorBgBody: theme["neutral-10"],
            colorBgTrigger: theme["dark-blue-100"]
        },
        Menu: {
            itemMarginInline: 0,
            radiusItem: 0,
            darkItemBg: theme["dark-blue-100"],
            darkItemColor: theme["neutral-0"],
            darkItemHoverBg: theme["dark-blue-120"],
            darkItemHoverColor: theme["primary-10"],
            darkItemSelectedBg: theme["dark-blue-100"],
            darkItemSelectedColor: theme["light-blue-100"],
            controlHeightLG: 52,
            marginXS: 0,
            marginXXS: 0 // Only for sider,
        },
        Card: {
            colorBgContainer: theme["neutral-0"],
            colorBorder: theme["neutral-20"],
            borderRadius: 6
        },
        Pagination: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            paginationItemSize: 20,
            paginationFontWeightActive: 500,
            paginationEllipsisLetterSpacing: 1,
            colorTextDisabled: theme['neutral-40'],
            colorText: theme['neutral-40'],
        },
        Avatar: {
            colorTextPlaceholder: "#c1c0c0"
        },
        Button: {
            controlHeight: 40
        },
        Input: { controlHeight: 40 },
        Select: {
            controlHeight: 40,
            fontSize: 14,
            colorTextDescription: theme["neutral-50"]
        },
        DatePicker: { controlHeight: 40 },
        Tabs: { margin: 0 },
        Drawer: { padding: 24, paddingLG: 48 },
        Tooltip: {
            colorBgDefault: theme["neutral-90"],
            fontSize: theme.fontSizeSM,
            lineHeight: theme.lineHeightSM,
            paddingSM: 16,
            paddingXS: 16
        },
        Notification: {
            colorBgElevated: "#313033",
            width: 334,
            paddingMD: 15,
            paddingContentHorizontalLG: 17,
            colorTextHeading: theme["neutral-0"],
            colorIcon: theme["neutral-0"],
            colorIconHover: theme["neutral-0"],
            marginXS: 0
        },
        Popover: {
            borderRadiusLG: 6
        }
    }
};
export const FormItem = styled(Form.Item)({
    marginBottom: 30,
    '& .ant-form-item-label': {
        paddingBottom: 9
    },
    '& .ant-form-item-label label': {
        fontSize: 14,
        lineHeight: '18px',
        fontWeight: 500,
    },
    '.ant-space': {
        gap: '16px !important'
    },
    '.ant-form-item-explain-error': {
        fontSize: "14px"
    },
    "& .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)": {
        "&::before": {
            content: "none"
        },
        "&::after": {
            display: "inline-block",
            marginInlineEnd: 4,
            content: '"*"',
            visibility: "visible"
        }
    }
});
export const Button = styled(AntdButton)(props => (props.icon && {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
}));
export const antdGlobalStyle = [
    `
        .ant-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow {
            top: 1px !important
        }
    `,
    {
        ".ant-avatar": {
            ".ant-avatar-string": {
                fontWeight: 500
            }
        },
        ".ant-tooltip": {
            ".ant-tooltip-inner": {
                opacity: theme.tooltipOpacity,
                textAlign: "center",
                maxWidth: 187
            },
            ".ant-tooltip-arrow": {
                opacity: theme.tooltipOpacity
            }
        },
        ".ant-input": {
            "&.ant-input-disabled": {
                border: "none",
                color: theme["neutral-100"]
            }
        },
        ".ant-picker": {
            "&.ant-picker-disabled": {
                border: "none",
            },
            "& .ant-picker-input >input[disabled]": {
                color: theme["neutral-100"]
            }
        },
        ".ant-picker-dropdown .ant-picker-date-panel": {
            width: 300,
            "& .ant-picker-content": {
                width: "100%"
            }
        },
        ".ant-select-dropdown": {
            ".ant-select-item-option-selected:not(.ant-select-item-option-disabled)": {
                fontWeight: 400
            },
            ".ant-select-item-group": {
                fontWeight: 600,
                "&:not(:first-of-type)": {
                    borderTop: `1px solid ${theme["neutral-20"]}`
                }
            },
            ".ant-select-item-option-grouped": {
                paddingInlineStart: 12
            }
        },
        ".ant-select": {
            "& .ant-select-selection-item-content": {
                fontSize: 14
            }
        },
        ".ant-modal-root .ant-modal-wrap": {
            overflow: "hidden"
        },
        ".ant-btn": {
            fontWeight: 500,
            lineHeight: 1,
            "&.ant-btn-default:not(:disabled)": {
                borderColor: mapToken.colorPrimary,
                color: mapToken.colorPrimary,
                "&:hover": {
                    color: mapToken.colorPrimaryHover,
                    borderColor: mapToken.colorPrimaryBorderHover
                }
            },
            "&.ant-btn-primary:disabled": {
                color: theme['neutral-0'],
                backgroundColor: mapToken.colorPrimary,
                opacity: .6
            }
        },
        ".ant-notification": {
            left: `${theme.sidebarWidth}px !important`,
            bottom: `calc(52px - 16px) !important`,
            "&.ant-notification-bottomLeft": {
                marginInlineStart: 52,
            }
        },
        ".ant-modal": {
            overflow: "hidden",
            "& .ant-modal-content": {
                padding: 0,
                display: "flex",
                flexDirection: "column",
                maxHeight: "calc(100vh - 96px)",
                "& .ant-modal-close": {
                    height: theme.modalCloseButtonSize,
                    width: theme.modalCloseButtonSize,
                    top: theme.modalPadding,
                    right: theme.modalPadding,
                    color: theme["neutral-100"]
                },
                "& .ant-modal-header": {
                    marginBottom: 0,
                    "& .ant-modal-title": {
                        padding: theme.modalPadding,
                        paddingRight: theme.modalPadding + theme.modalCloseButtonSize + theme.modalPadding,
                        color: theme["neutral-100"],
                        borderBottom: `1px solid ${theme["neutral-20"]}`,
                        fontWeight: 500,
                        fontSize: 24,
                        lineHeight: "28px",
                    }
                },
                ".ant-modal-body": [{
                        padding: theme.modalPadding,
                        maxWidth: "100%"
                    }, customScroll],
                "&:has(.ant-modal-footer) .ant-modal-body": {
                    paddingBottom: 0
                },
                "& .ant-modal-footer": {
                    marginTop: 0,
                    padding: theme.modalPadding
                }
            },
        },
        ".ant-space.full-width": {
            display: "flex",
            flex: "1 1 0",
            "& .ant-space-item": {
                flex: "1 1 0",
                "& .ant-btn": {
                    width: "100%"
                }
            }
        },
        ".ant-drawer": {
            "& .ant-drawer-body": [{
                    paddingTop: antdThemeConfig.components.Drawer.padding
                }, customScroll],
            "& .ant-drawer-footer": {
                paddingTop: antdThemeConfig.components.Drawer.padding,
                paddingBottom: antdThemeConfig.components.Drawer.paddingLG,
                ...paddingX(antdThemeConfig.components.Drawer.paddingLG),
                borderTop: 0,
            },
            "& .ant-drawer-close": {
                position: "absolute",
                right: 0,
                marginInlineEnd: antdThemeConfig.components.Drawer.paddingLG,
                padding: 0
            }
        },
        ".ant-tabs": {
            "&.ant-tabs-top > div:first-child": {
                marginBottom: 24
            },
            "& .ant-tabs-tab": {
                paddingTop: 0,
                justifyContent: "center"
            }
        },
        ".ant-pagination": {
            display: "flex",
            justifyContent: "center",
            fontSize: 14,
            lineHeight: '24px',
            marginTop: 26,
            "& .ant-pagination-item": {
                border: "none"
            }
        },
        ".ant-table-wrapper .ant-table-pagination.ant-pagination": {
            marginTop: 26,
        },
        ".ant-table-wrapper": {
            "& .ant-table-thead > tr > th, & .ant-table-thead > tr > td": {
                backgroundColor: "unset",
                fontWeight: 400,
                lineHeight: "22px",
                color: theme["neutral-50"],
                borderBottomWidth: .5,
                borderBottomColor: theme["neutral-30"],
                padding: 10,
                "&:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before": {
                    content: "none"
                }
            },
            "& .ant-table-thead:hover > tr:hover > th, & .ant-table-thead:hover > tr:hover > td, & .ant-table-tbody > tr.ant-table-row:hover > td": {
                backgroundColor: theme["neutral-10"],
            },
            "& .ant-table-tbody > tr > td": {
                borderBottomWidth: .5,
                borderBottomColor: theme["neutral-30"],
                padding: 10,
                color: theme["neutral-100"],
            },
            "& .ant-table-tbody > tr.ant-table-row-selected > td, & .ant-table-tbody > tr.ant-table-row-selected:hover > td": {
                background: theme["primary-40"]
            },
            "& td.ant-table-column-sort, & .ant-table-thead th.ant-table-column-sort": {
                backgroundColor: "unset"
            },
            "& .ant-table-column-sorters": {
                justifyContent: "unset"
            },
            "& .ant-table-column-title": {
                flex: "unset"
            }
        }
    }
];
