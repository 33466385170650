import { register } from "@ebay/nice-modal-react";
import { ProjectCreatedModal } from "./project-created-modal";
import { ProjectDetailModal } from "./project-detail-modal";
import { ProjectEditModal } from "./project-edit-modal";
import { ProjectShareModal } from "./project-share-modal";
import { TripDetailModal } from "./trip-detail-modal";
import { TripEditModal } from "./trip-edit-modal";
import { TripPointAddModal } from "./trip-point-add-modal";
import { TripPointEditModal } from "./trip-point-edit-modal";
export { AttachmentsTable } from "./attachments-table";
export { TripsTable } from "./trips-table";
export const registerModals = () => {
    register('trip-edit-modal', TripEditModal);
    register('trip-point-add-modal', TripPointAddModal);
    register('trip-point-edit-modal', TripPointEditModal);
    register('trip-detail-modal', TripDetailModal);
    register('project-edit-modal', ProjectEditModal);
    register('project-created-modal', ProjectCreatedModal);
    register('project-share-modal', ProjectShareModal);
    register('project-detail-modal', ProjectDetailModal);
};
