import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css, cx } from "@emotion/css";
import { Avatar } from "antd";
import { textEllipsis } from "~/styles";
const style = ({ avatarSize, lineClamp }) => css({
    display: "flex",
    alignItems: "center",
    "& .avatar-text": {
        marginLeft: 10,
        width: `calc(100% - 10px - ${avatarSize}px)`,
        "& p": {
            maxWidth: "100%",
            ...textEllipsis(lineClamp)
        }
    }
});
export const ProjectAvatar = ({ project, avatarSize = 40, extraChildren, className, lineClamp = 2 }) => {
    const avatarProps = project.image ?
        { src: project.image } :
        { style: { backgroundColor: project.color }, children: project.initials };
    return (_jsxs("div", { className: cx(style({ avatarSize, lineClamp }), "project-avatar", className), children: [_jsx(Avatar, { className: "project-avatar", shape: "square", size: avatarSize, ...avatarProps }), _jsxs("div", { className: 'avatar-text', children: [_jsx("p", { className: "type-badge-4 my-0", children: project.name }), extraChildren !== undefined && extraChildren] })] }));
};
