import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css, cx } from "@emotion/css";
import { useRequest } from "ahooks";
import { AutoComplete, Input, Spin } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AvatarText, EmptyContent } from "~/components";
import { SvgIcon } from "~/icons";
import { ProjectManagementService } from "~/services";
import { customScroll } from "~/styles";
import { usePermissions } from "~/utils";
import { LoggedUserPopover } from "./logged-user-popover";
import { NotificationsPopover } from "./notifications/notifications-popover";
const style = css({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    ".ant-select-auto-complete": {
        width: 436,
        ".ant-input-prefix": {
            marginRight: 12,
            ".anticon-loading": {
                fontSize: 16
            }
        },
    },
    ".ant-avatar": {
        cursor: 'pointer',
        border: '4px solid #f7f6f6',
    }
});
const dropdownStyle = css({
    padding: "0",
    ".rc-virtual-list-holder": {
        ...customScroll,
        maxHeight: "470px !important"
    },
    ".ant-select-item.ant-select-item-group": {
        padding: "12px 12px 6px",
        minHeight: "auto",
        "&:not(:first-child)": {
            marginTop: 6,
        }
    },
    ".ant-select-item": {
        padding: "6px 12px",
        "&:last-child": {
            paddingBottom: 12
        },
        ".ant-avatar": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px !important"
        }
    }
});
function formatNestedOptions(dataItems, category, keyProp, valueProp, AvatarComponent, componentProp, query) {
    return dataItems.map((e, i) => ({
        key: `${category}/${i}/${e[keyProp]}`,
        label: _jsx(AvatarComponent, { [componentProp]: e, highlightText: { query } }),
        value: `${category}/${i}/${e[keyProp]}`
    }));
}
function formatOptions(data, query) {
    if (!data || !query)
        return [];
    const files = data.files.slice(0, 2);
    const users = data.members ? data.members.slice(0, 2) : [];
    const trips = data.trips.slice(0, 2);
    const projects = data.projects.slice(0, 2);
    const options = [];
    if (users.length > 0)
        options.push({
            label: "Utilizadores",
            options: formatNestedOptions(users, "members", "sub", "name", AvatarText.User, "user", query)
        });
    if (projects.length > 0)
        options.push({
            label: "Projetos",
            options: formatNestedOptions(projects, "projects", "id", "name", AvatarText.Project, "project", query)
        });
    if (trips.length > 0)
        options.push({
            label: "Visitas de campo",
            options: formatNestedOptions(trips, "trips", "id", "name", AvatarText.Trip, "trip", query)
        });
    if (files.length > 0)
        options.push({
            label: "Ficheiros",
            options: formatNestedOptions(files, "files", "id", "name", AvatarText.File, "file", query)
        });
    return options;
}
const Search = () => {
    const [value, setValue] = useState();
    const [data, setData] = useState();
    const req = useRequest(ProjectManagementService.search, { manual: true, debounceWait: 500 });
    const navigate = useNavigate();
    const permissions = usePermissions(["VIEW_USERS_PAGE"]);
    const options = useMemo(() => {
        if (req.data) {
            !permissions.VIEW_USERS_PAGE && delete req.data.members;
            setData(req.data);
        }
        return formatOptions(req.data, req.params[0]?.query);
    }, [req.data, req.params]);
    const onSearch = (value) => {
        if (value && value.length > 0)
            req.run({ query: value, size: 2 });
        req.mutate(undefined);
    };
    const onSelect = (value, option) => {
        const [category, _, id] = option.key.split("/");
        let project_id;
        switch (category) {
            case "members":
                navigate({ pathname: "/users", search: `?sub=${id}&sort_by=name&sort_order=asc` });
                break;
            case "projects":
                navigate({ pathname: `/projects/${id}/trips`, search: "?showProjectDetail=true" });
                break;
            case "trips":
                // eslint-disable-next-line no-case-declarations
                project_id = data["trips"].find(t => t.id == id)["project_id"];
                project_id && navigate({ pathname: `/projects/${project_id}/trips`, search: `?tripId=${id}` });
                break;
            case "files":
                /* eslint-disable no-case-declarations */
                const file = data["files"].find(f => f.id == id);
                const path = file["type"] == 0 ? "attachments" : "geofiles";
                project_id = file["project_id"];
                /* eslint-enable no-case-declarations */
                project_id && navigate({ pathname: `/projects/${project_id}/${path}`, search: `?fileId=${id}` });
                break;
        }
        setValue("");
        req.mutate(undefined);
    };
    const notFoundContent = req.data && (_jsx(EmptyContent, { description: "N\u00E3o foram encontrados resultados" }));
    return (_jsx(AutoComplete, { allowClear: true, options: options, value: value, onChange: setValue, onSearch: onSearch, onSelect: onSelect, popupClassName: dropdownStyle, virtual: false, notFoundContent: notFoundContent, children: _jsx(Input, { placeholder: "Pesquisa", prefix: req.loading ? _jsx(Spin, {}) : _jsx(SvgIcon, { name: "ic_search" }) }) }));
};
export const Header = ({ className }) => {
    return (_jsxs("div", { className: cx(style, className), children: [_jsx(Search, {}), _jsxs("div", { className: "d-flex align-center", style: { gap: 26 }, children: [_jsx(NotificationsPopover, {}), _jsx(LoggedUserPopover, {})] })] }));
};
