import { jsx as _jsx } from "react/jsx-runtime";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Form } from "antd";
import { mapValues, pick } from "lodash-es";
import { createContext, useCallback, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import config, { theme } from "~/config";
import { SvgIcon } from "./icons";
import { AppError } from "./types";
export const defaultModalProps = (modal) => ({
    open: modal.visible,
    centered: true,
    maskClosable: false,
    closeIcon: _jsx(SvgIcon, { name: "ic_close", size: 28, color: theme["neutral-100"] }),
    onOk: (args) => {
        modal.resolve(args);
        modal.hide();
    },
    onCancel: () => modal.hide(),
    afterClose: () => {
        modal.resolveHide();
        if (!modal.keepMounted)
            modal.remove();
    },
});
export const defaultDrawerProps = (drawer) => ({
    open: drawer.visible,
    closeIcon: _jsx(SvgIcon, { name: "ic_close", size: 28, color: theme["neutral-100"] }),
    onClose: () => drawer.hide(),
    afterOpenChange: (open) => {
        if (!open)
            drawer.resolveHide();
        if (!open && !drawer.keepMounted)
            drawer.remove();
    }
});
export function formatBytes(bytes, decimals) {
    if (bytes == 0)
        return '0 Bytes';
    const k = 1024, dm = decimals || 2, sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'], i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export function serializeFormQuery(params) {
    const paramsObj = {};
    for (const [key, value] of params.entries()) {
        if (paramsObj[key]) {
            Array.isArray(paramsObj[key]) ?
                paramsObj[key].push(value) :
                paramsObj[key] = [paramsObj[key], value];
        }
        else {
            paramsObj[key] = value;
        }
    }
    return paramsObj;
}
export function getResponsiveValue(breakpoints, values, defaultValue) {
    const currentBreaks = Object.entries(breakpoints).filter(([_, v]) => !!v).map(([k, _]) => k);
    for (const b of currentBreaks.reverse()) {
        if (values[b])
            return values[b];
    }
    return defaultValue;
}
export function useErrorBoundary() {
    const [_, setError] = useState();
    return useCallback(e => setError(() => { throw e; }), [setError]);
}
export function formatDistance(meters) {
    if (meters < 1000)
        return `${meters} m`;
    return `${(meters / 1000).toFixed(0)} km`;
}
export function createElement(tagName, className) {
    const el = document.createElement(tagName);
    if (className)
        el.className = className;
    return el;
}
export const MapContext = createContext(null);
export class MapControl {
    _map;
    _container;
    constructor({ container }) {
        this._container = container;
    }
    onAdd(map) {
        this._map = map;
        return this._container;
    }
    onRemove() {
        this._container.parentNode?.removeChild(this._container);
        this._map = undefined;
    }
}
export function isAppError(error) {
    return error instanceof AppError;
}
export function usePermissions(permissions, extraArgs) {
    const _permissions = usePermissionsFn(permissions);
    return mapValues(_permissions, func => func(extraArgs));
}
export function usePermissionsFn(permissions) {
    const { user, route } = useAuthenticator(context => [context.user, context.route]);
    const isAuthenticated = route === "authenticated";
    const staticArgs = isAuthenticated ? {
        userGroups: user.getSignInUserSession()?.getAccessToken().payload["cognito:groups"],
        userId: user.attributes?.sub
    } : {};
    const _permissions = pick(config.permissions, permissions);
    const funcWrapper = isAuthenticated ?
        func => args => func({ ...staticArgs, ...args }) :
        () => () => false;
    return mapValues(_permissions, funcWrapper);
}
export function withPermissions(Component, permissions) {
    const WithPermissionsComponent = () => {
        const { route } = useAuthenticator(context => [context.route]);
        const location = useLocation();
        const _permissions = usePermissions(permissions);
        if (route !== "authenticated")
            return _jsx(Navigate, { replace: true, to: "/login", state: { from: location } });
        if (permissions.length > 0) {
            const allowed = Object.values(_permissions).some(e => !!e);
            if (!allowed)
                throw new AppError("FORBIDDEN");
        }
        return _jsx(Component, {});
    };
    WithPermissionsComponent.displayName = Component.displayName;
    return WithPermissionsComponent;
}
export function useValidateForm(form) {
    const [isFormValid, setIsFormValid] = useState(true);
    const values = Form.useWatch([], form);
    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsFormValid(true))
            .catch(error => (error.errorFields.length > 0) && setIsFormValid(false));
    }, [values]);
    return [isFormValid, values];
}
export const formRules = {
    required: { required: true, message: "" },
    email: { type: "email", message: "Insira um email válido" },
};
export const globalNotificationConfig = { placement: "bottomLeft", className: "ren-notification" };
export const fileContentTypeIcon = {
    "image": _jsx(SvgIcon, { name: "ic_picture" }),
    "audio": _jsx(SvgIcon, { name: "ic_mic" }),
    "video": _jsx(SvgIcon, { name: "ic_video" }),
    "text": _jsx(SvgIcon, { name: "ic_file_txt" }),
    "file": _jsx(SvgIcon, { name: "ic_files" })
};
export function fileIcon(contentType) {
    return fileContentTypeIcon[contentType] || fileContentTypeIcon.file;
}
function convertDDToDMS(deg, lng) {
    let d = parseInt(deg.toString());
    const minfloat = Math.abs((deg - d) * 60);
    let m = Math.floor(minfloat);
    const secfloat = (minfloat - m) * 60;
    let s = Math.round((secfloat + Number.EPSILON) * 100) / 100;
    d = Math.abs(d);
    if (s == 60) {
        m++;
        s = 0;
    }
    if (m == 60) {
        d++;
        m = 0;
    }
    const dms = {
        dir: deg < 0 ? lng ? 'W' : 'S' : lng ? 'E' : 'N',
        deg: d,
        min: m,
        sec: s
    };
    return `${dms.deg}\u00B0${dms.min}'${dms.sec}"${dms.dir}`;
}
export const convertWGS84IntoETRS89 = (coordinates) => {
    const latitude = coordinates.split(",")[0];
    const longitude = coordinates.split(",")[1];
    const lat = convertDDToDMS(latitude, false);
    const lon = convertDDToDMS(longitude, true);
    return `${lat}, ${lon}`;
};
export const numberFormatter = Intl.NumberFormat('en', { notation: 'compact' });
