import { css } from "@emotion/css";
const hiddenStyle = {
    visibility: "hidden",
    "& *": {
        opacity: 0
    }
};
export default (hidden) => css({
    ...(hidden && hiddenStyle),
    "& .ant-modal-content .ant-modal-footer": {
        display: "flex",
        justifyContent: "flex-end",
        "& .ant-btn": {
            width: 206
        }
    },
});
