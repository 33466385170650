import { jsx as _jsx } from "react/jsx-runtime";
import { LoadingOutlined } from '@ant-design/icons';
import { css, cx } from "@emotion/css";
import { Spin } from "antd";
import { memo } from 'react';
const style = css({
    width: '100%',
    marginBottom: 10,
    marginTop: 10,
    padding: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .ant-spin-text": {
        marginTop: 10
    }
});
export const Loading = memo(function Loading({ className }) {
    return _jsx(Spin, { className: cx(className, style, "ren-loading"), indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) });
});
