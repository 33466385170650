import { jsx as _jsx } from "react/jsx-runtime";
import { UserOutlined } from '@ant-design/icons';
import { Upload, message } from "antd";
import ImgCrop from 'antd-img-crop';
import { useEffect, useState } from "react";
export const UploadCrop = ({ value, disabled, uploadButton, listType, onChange, className }) => {
    const [fileList, setFileList] = useState([]);
    useEffect(() => { value && setFileList(value); }, [value]);
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('A imagem deve ser no formato JPG/PNG');
            return Upload.LIST_IGNORE;
        }
        const isLt4M = file.size / 1024 / 1024 < 4;
        if (!isLt4M) {
            message.error('A imagem deve ter no máximo 4MB');
            return Upload.LIST_IGNORE;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const fileObj = [{ url: reader.result, base64: reader.result.split(',').pop(), uid: "0", name: "profile.png" }];
            setFileList(fileObj);
            onChange?.(fileObj);
        };
        return false;
    };
    const onRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
        onChange?.(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        image.style.height = "100%";
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    return (_jsx(ImgCrop, { modalTitle: "Editar imagem", quality: 0.8, children: _jsx(Upload, { className: className, listType: listType, fileList: fileList, maxCount: 1, beforeUpload: beforeUpload, onRemove: onRemove, onPreview: onPreview, children: fileList?.length == 0 && (disabled ? _jsx(UserOutlined, { style: { fontSize: 40 } }) : uploadButton) }) }));
};
