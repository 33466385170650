import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { App, Col, DatePicker, Form, Input, Modal, Row, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import map from 'lodash/map';
import pick from 'lodash/pick';
import { useEffect, useState } from "react";
import { MembersInput } from "~/components/members-selection";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { ProjectManagementService } from "~/services";
import { Button, FormItem } from "~/styles";
import { defaultModalProps, formRules, globalNotificationConfig, useValidateForm } from "~/utils";
import style from "./style";
export const TripEditModal = createModal(({ projectId, projectStatus, trip }) => {
    const [tripPoints, setTripPoints] = useState([]);
    const [members, setMembers] = useState([]);
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const [form] = useForm();
    const [isFormValid] = useValidateForm(form);
    const { notification } = App.useApp();
    useEffect(() => {
        ProjectManagementService.getProjectTripPoints(projectId)
            .then(data => data.map(r => ({ label: `${r.sequence_id}. ${r.name}`, value: r.id })))
            .then(setTripPoints);
        ProjectManagementService.getProjectMembers(projectId).then(m => setMembers(m));
    }, [projectId]);
    const editMode = !!trip;
    const initialValues = editMode ?
        { ...pick(trip, ["name", "description", "members", "status"]), trip_date: dayjs(trip.trip_date), points: trip.points?.map(t => t.id) || [] }
        :
            { name: "", description: "", trip_date: dayjs(), members: [], points: [] };
    const onFinish = (values) => {
        const data = {
            ...values,
            trip_date: values.trip_date.format("YYYY-MM-DD"),
            project_id: projectId,
            points: values.points,
            members: map(values.members, "sub")
        };
        if (editMode) {
            const touchedFields = Object.keys(values).filter(form.isFieldTouched);
            ProjectManagementService.updateTrip(trip.id, pick(data, touchedFields))
                .then(() => notification.success({ message: "As suas alterações foram salvas", ...globalNotificationConfig }))
                .then(defaultProps.onOk)
                .catch(() => notification.error({ message: 'Erro a editar a visita de campo', ...globalNotificationConfig }));
        }
        else {
            ProjectManagementService.createTrip(data)
                .then(() => notification.success({ message: "Visita de campo criada", ...globalNotificationConfig }))
                .then(defaultProps.onOk)
                .catch(() => notification.error({ message: 'Erro a criar a visita de campo', ...globalNotificationConfig }));
        }
    };
    const confirmationModal = useModal("confirmation-modal");
    const onCancelClick = () => {
        confirmationModal.show({
            sentence: "Tem a certeza que quer descartar as alterações?",
            cancelLabel: 'Não', confirmationLabel: 'Sim'
        }).then(() => modal.hide());
    };
    const saveModal = useModal("confirmation-modal");
    const onSaveClick = () => {
        if (projectStatus == 1 && (!editMode || form.getFieldValue("status") == 0)) {
            const sentence = editMode ?
                "Ao editar o estado da visita para “Aberto”, automaticamente será alterado o estado do projeto para “Em progresso”" :
                "Ao criar uma nova visita, o estado do projeto será automaticamente alterado para “Em progresso”";
            saveModal.show({
                sentence,
                confirmationLabel: 'Ok',
                onCancel: () => { form.setFieldValue("status", initialValues["status"]); }
            }).then(() => onFinish(form.getFieldsValue()));
        }
        else {
            onFinish(form.getFieldsValue());
        }
    };
    const modalProps = {
        ...defaultProps,
        title: editMode ? "Editar visita de campo" : "Criar visita de campo",
        closeIcon: _jsx(SvgIcon, { name: "ic_close", size: 28, color: theme["neutral-100"] }),
        onCancel: onCancelClick,
        footer: (_jsxs(Space, { size: 24, children: [_jsx(Button, { onClick: onCancelClick, children: "Cancelar" }), _jsx(Button, { type: "primary", onClick: onSaveClick, disabled: !isFormValid, children: editMode ? "Guardar alterações" : "Criar" })] }))
    };
    return (_jsxs(Modal, { destroyOnClose: true, ...modalProps, className: style, width: 944, children: [_jsx("div", { className: "type-body-4 mb-7", style: { color: theme["neutral-50"] }, children: "* Campo de preenchimento obrigat\u00F3rio" }), _jsx(Form, { id: "trip-edit-form", form: form, layout: "vertical", initialValues: initialValues, preserve: false, children: _jsxs(Row, { gutter: 24, children: [_jsx(Col, { span: editMode ? 24 : 12, children: _jsx(FormItem, { label: "Nome", name: "name", rules: [formRules.required], children: _jsx(Input, { placeholder: "Introduzir nome da visita", maxLength: 140 }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Data", name: "trip_date", rules: [formRules.required], children: _jsx(DatePicker, { placeholder: "Selecionar data", allowClear: true, showToday: false, className: "w-100", format: value => value.locale('pt-PT').format("D MMM YYYY") }) }) }), editMode && (_jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Estado", name: "status", children: _jsx(Select, { options: [{ value: 0, label: 'Aberto' }, { value: 1, label: 'Completo' }] }) }) })), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Pontos de visita", name: "points", children: _jsx(Select, { mode: "multiple", options: tripPoints, maxTagTextLength: 10, placeholder: "Escolher pontos de visita" }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Membros", name: "members", children: _jsx(MembersInput, { members: members, belongsToTrip: true }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Descri\u00E7\u00E3o", name: "description", children: _jsx(Input.TextArea, { showCount: { formatter: args => `${args.count}/${args.maxLength} caracteres` }, maxLength: 2000, style: { height: 225, resize: 'none' }, placeholder: "Adicionar descri\u00E7\u00E3o do projeto" }) }) })] }) })] }));
});
