import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { App, Col, Form, Input, Modal, Radio, Row, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { Auth } from "aws-amplify";
import pick from 'lodash/pick';
import { useEffect, useState } from "react";
import { UploadCrop } from "~/components/upload-crop";
import config from "~/config";
import { SvgIcon } from "~/icons";
import { ProjectManagementService } from "~/services";
import { Button, FormItem } from "~/styles";
import { defaultModalProps, formRules, globalNotificationConfig, useValidateForm } from "~/utils";
import { uploadCropStyle, userModalStyle } from "./style";
const uploadButton = (_jsxs("div", { className: 'd-flex align-center', children: [_jsx("p", { className: "my-0 mr-3 ant-upload-drag-icon", children: _jsx(SvgIcon, { name: "ic_camera", size: 16, color: config.theme["neutral-50"] }) }), _jsx("div", { style: { textAlign: 'left' }, children: _jsx("p", { style: { color: config.theme["neutral-50"], fontWeight: 400 }, className: 'my-0 type-body-2', children: "Clique para upload" }) })] }));
export const UserEditModal = createModal(({ user }) => {
    const [groups, setGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const modal = useModal();
    const [form] = useForm();
    const { user: loggedUser } = useAuthenticator(context => [context.user]);
    const [isFormValid] = useValidateForm(form);
    const { notification } = App.useApp();
    useEffect(() => { ProjectManagementService.getUserGroups().then(groups => setGroups(groups)); }, []);
    const editMode = !!user;
    const initialValues = editMode ? // TODO: Add FormValues type
        {
            ...pick(user, ["name", "custom:office", "email", "group"]),
            picture: user.picture ? [{ uid: '-1', name: 'profile.png', status: 'done', 'url': user.picture || "" }] : [],
        }
        :
            { picture: [] };
    const confirmationModal = useModal("confirmation-modal");
    const onCancelClick = () => {
        confirmationModal.show({
            sentence: "Tem a certeza que quer descartar as alterações?",
            cancelLabel: 'Não', confirmationLabel: 'Sim'
        }).then(() => modal.hide());
    };
    const defaultProps = defaultModalProps(modal);
    const modalProps = {
        ...defaultProps,
        open: modal.visible,
        title: editMode ? "Editar utilizador" : "Criar utilizador",
        closeIcon: _jsx(SvgIcon, { name: "ic_close", size: 28, color: config.theme["neutral-100"] }),
        onCancel: onCancelClick,
        footer: (_jsxs(Space, { size: 24, children: [_jsx(Button, { onClick: onCancelClick, disabled: isLoading, children: "Cancelar" }), _jsx(Button, { type: "primary", htmlType: "submit", disabled: !isFormValid || isLoading, loading: isLoading, form: "user-form", children: editMode ? "Guardar" : "Criar" })] }))
    };
    const onFinish = (formValues) => {
        setIsLoading(true);
        const data = {
            name: formValues['name'],
            email: formValues['email'],
            "custom:office": formValues['custom:office'],
            group: formValues['group']
        };
        if (formValues['picture'].length)
            data["picture"] = formValues["picture"][0]["base64"];
        let promise;
        let errorMessage;
        if (editMode) {
            errorMessage = "Erro a atualizar o utilizador";
            const touchedFields = Object.keys(formValues).filter(form.isFieldTouched);
            const body = pick(data, touchedFields);
            // Add sub to update user method
            body["sub"] = user.sub;
            if (touchedFields.includes("picture") && body["picture"] == undefined) {
                body["picture"] = null;
            }
            promise = ProjectManagementService.updateUser(body)
                .then(() => notification.success({ message: "As suas alterações foram salvas", ...globalNotificationConfig }));
        }
        else {
            errorMessage = "Erro a criar o utilizador";
            promise = ProjectManagementService.createUser(data)
                .then(() => notification.success({ message: "As suas alterações foram salvas", ...globalNotificationConfig }));
        }
        promise
            .then(defaultProps.onOk)
            .then(() => {
            if (user?.sub === loggedUser?.attributes?.sub) {
                Auth.currentAuthenticatedUser({ bypassCache: true });
            }
        })
            .catch((e) => {
            if (e.response?.status === 409)
                errorMessage = "Utilizador já existe";
            notification.error({ message: errorMessage, ...globalNotificationConfig });
        })
            .finally(() => setIsLoading(false));
    };
    return (_jsxs(Modal, { width: 944, className: userModalStyle, ...modalProps, children: [_jsx("div", { className: "type-body-4 mb-7", style: { color: config.theme["neutral-50"] }, children: "* Campo de preenchimento obrigat\u00F3rio" }), _jsx(Form, { id: "user-form", form: form, layout: "vertical", initialValues: initialValues, preserve: false, onFinish: onFinish, children: _jsxs(Row, { gutter: 24, children: [_jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Nome completo", name: "name", rules: [formRules.required], children: _jsx(Input, { placeholder: "Adicionar primeiro e ultimo nome", maxLength: 100 }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Cargo", name: "custom:office", children: _jsx(Input, { placeholder: "Adicionar cargo", maxLength: 100 }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Email", name: "email", rules: [formRules.required, formRules.email], children: _jsx(Input, { placeholder: "Adicionar email", maxLength: 100, disabled: user && editMode }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Fotografia", name: "picture", children: _jsx(UploadCrop, { disabled: false, listType: "picture", uploadButton: uploadButton, className: uploadCropStyle }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Grupo", name: "group", rules: [formRules.required], children: _jsx(Radio.Group, { children: groups.map(g => _jsx(Radio, { value: g, children: g }, g)) }) }) })] }) })] }));
});
