import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { css } from "@emotion/css";
import { Col, DatePicker, Form, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { pick } from "lodash-es";
import { useEffect, useState } from "react";
import { AvatarText, StatusTag, TripAvatar } from "~/components";
import { ProjectManagementService } from "~/services";
import { FormItem } from "~/styles";
import { defaultModalProps, usePermissions } from "~/utils";
import { FileList } from "./file-list";
const style = css({
    "& .members": {
        margin: 0,
        padding: 0,
        display: "grid",
        listStyleType: "none",
        gridTemplateColumns: "repeat(4, 194px)",
        columnGap: 40,
        rowGap: 18
    }
});
export const TripDetailModal = createModal(({ tripId }) => {
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const [form] = useForm();
    const permissions = usePermissions(["VIEW_ATTACHMENTS"]);
    const [formValues, setFormValues] = useState(undefined);
    useEffect(() => {
        if (!tripId)
            return;
        Promise.all([
            ProjectManagementService.getTrip(tripId),
            ProjectManagementService.getTripAttachments(tripId).catch(error => [])
        ])
            .then(([trip, attachments]) => {
            setFormValues({
                ...pick(trip, ["name", "description", "members", "sequence_id", "status"]),
                trip_date: dayjs(trip.trip_date),
                points: trip.points?.map(t => `${t.sequence_id}. ${t.name}`).join('\n') || "",
                attachments
            });
        });
    }, [tripId]);
    if (!formValues)
        return null;
    return (_jsx(Modal, { ...defaultProps, className: style, title: _jsx(TripAvatar, { trip: formValues }), width: 944, footer: null, children: _jsx(Form, { disabled: true, form: form, layout: "vertical", initialValues: formValues, children: _jsxs(Row, { gutter: 24, children: [_jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Data", name: "trip_date", children: _jsx(DatePicker, { className: "w-100", format: value => value.locale('pt-PT').format("D MMM YYYY"), suffixIcon: null }) }) }), _jsx(Col, { span: 12, children: _jsx(FormItem, { label: "Estado", name: "status", children: _jsx(StatusTag.Trip, { status: formValues.status }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Pontos de visita", name: "points", children: _jsx(TextArea, { autoSize: true, style: { minHeight: '40px' }, placeholder: "Sem pontos de visita" }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Descri\u00E7\u00E3o", name: "description", children: _jsx(TextArea, { autoSize: true, style: { minHeight: '40px' }, placeholder: "Sem descri\u00E7\u00E3o" }) }) }), _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Membros", name: "members", children: _jsx("ul", { className: "members", children: formValues.members.map(member => _jsx("li", { children: _jsx(AvatarText.User, { user: member }) }, member.sub)) }) }) }), permissions.VIEW_ATTACHMENTS &&
                        _jsx(Col, { span: 24, children: _jsx(FormItem, { label: "Anexos", children: _jsx(FileList, { files: formValues.attachments, placeholder: "Sem anexos" }) }) })] }) }) }));
});
