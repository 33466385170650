import { jsx as _jsx } from "react/jsx-runtime";
import { Tag } from 'antd';
import React from 'react';
import { theme } from '~/config';
const projectOptions = {
    0: { color: theme["orange-50"], style: { color: theme["orange-100"] }, children: "Em progresso" },
    1: { color: theme["green-50"], style: { color: theme["green-100"] }, children: "Completo" }
};
export const Project = React.memo(function Project({ status }) {
    const props = projectOptions[status] || { children: status };
    return _jsx(Tag, { ...props, className: "type-badge-1" });
});
const tripOptions = {
    0: { color: theme["purple-50"], style: { color: theme["purple-100"] }, children: "Aberto" },
    1: { color: theme["green-50"], style: { color: theme["green-100"] }, children: "Completo" }
};
export const Trip = React.memo(function Trip({ status }) {
    const props = tripOptions[status] || { children: status };
    return _jsx(Tag, { ...props, className: "type-badge-1" });
});
