import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Tooltip } from "antd";
import { createContext, useContext, useReducer } from "react";
const reducer = (state, action) => {
    switch (action.type) {
        case "SHOW_TOOLTIP":
            return { ...state, [action.value.key]: action.value.text };
        case "REMOVE_TOOLTIP": {
            const _state = { ...state };
            delete _state[action.key];
            return _state;
        }
        default:
            return state;
    }
};
export const InfoTooltipContext = createContext(undefined);
export const InfoTooltipProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {});
    const value = {
        tooltips: state,
        showTooltip: value => dispatch({ type: "SHOW_TOOLTIP", value }),
        removeTooltip: (key) => dispatch({ type: "REMOVE_TOOLTIP", key }),
    };
    return (_jsx(InfoTooltipContext.Provider, { value: value, children: children }));
};
export const InfoTooltip = ({ tooltipKey, children, ...props }) => {
    const tooltipContext = useContext(InfoTooltipContext);
    if (!tooltipContext.tooltips[tooltipKey])
        return _jsx(_Fragment, { children: children });
    const onOpenChange = open => !open && tooltipContext.removeTooltip(tooltipKey);
    const title = tooltipContext.tooltips[tooltipKey];
    return (_jsx(Tooltip, { defaultOpen: true, destroyTooltipOnHide: true, placement: "bottom", trigger: ["click", "hover"], ...props, onOpenChange: onOpenChange, title: title, children: children }));
};
