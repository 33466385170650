import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { isAxiosError } from "axios";
import { isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom";
import { Button } from "~/styles";
import { isAppError } from "~/utils";
const style = css({
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(37, 37, 39, 0.5)",
    position: "absolute",
    zIndex: 10000,
    left: 0,
    top: 0,
    "& .result-modal": {
        height: "298px",
        width: "315px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 12,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.12)",
        padding: 32,
        "& .icon-wrapper": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "120px",
            "& .icon": {
                height: "82px",
                width: "82px",
                "&.error": {
                    backgroundImage: "url('/img/ic_error_message.svg')"
                },
                "&.permission": {
                    backgroundImage: "url('/img/ic_error_permission.svg')"
                }
            }
        },
        "& .title": {
            fontWeight: 600,
            lineHeight: "20px",
            fontSize: "16px",
            textAlign: "center"
        },
        "& .sub-title": {
            marginTop: "8px",
            color: "rgba(118, 118, 118, 1)",
            lineHeight: "18px",
            fontSize: "14px",
            textAlign: "center"
        },
        "& .back": {
            marginTop: "28px",
            textAlign: "center",
            "& button": {
                width: "118px"
            }
        }
    }
});
export const ErrorFallback = () => {
    const error = useRouteError();
    const navigate = useNavigate();
    const resultProps = {
        title: "Ocorreu um erro inesperado",
        subTitle: "Não é possível apresentar o que procura",
        status: "error"
    };
    if (isAxiosError(error) && error.response?.status) {
        switch (error.response.status) {
            case 403:
                resultProps.title = "Não tem permissões para aceder";
                resultProps.status = "403";
                break;
        }
    }
    else if (isRouteErrorResponse(error)) {
        switch (error.status) {
            case 403:
                resultProps.title = "Não tem permissões para aceder";
                resultProps.status = "403";
                break;
        }
    }
    else if (isAppError(error)) {
        switch (error.code) {
            case "FORBIDDEN":
                resultProps.title = "Não tem permissões para aceder";
                resultProps.status = "403";
                break;
        }
    }
    return (_jsx("div", { className: style, children: _jsxs("div", { className: "result-modal", children: [_jsx("div", { className: "icon-wrapper", children: _jsx("div", { className: `icon ${resultProps.status === "403" ? "permission" : "error"}` }) }), _jsx("div", { className: "title", children: resultProps.title }), _jsx("div", { className: "sub-title", children: resultProps.subTitle }), _jsx("div", { className: "back", children: _jsx(Button, { type: "primary", onClick: () => navigate("/"), children: "Voltar" }) })] }) }));
};
