import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { Input } from "antd";
import { theme } from "~/config";
import { SvgIcon } from "~/icons";
import { S3 } from "~/services";
import { padding, textEllipsis1 } from "~/styles";
import { formatBytes, usePermissions } from "~/utils";
const style = css({
    margin: 0,
    padding: 0,
    display: "grid",
    listStyleType: "none",
    columnGap: 40,
    rowGap: 18,
    gridTemplateColumns: "repeat(4, 215px)",
    gap: 12,
    "& .file-wrapper": {
        display: "flex",
        cursor: "pointer",
        ...padding(6, 10),
        color: theme["neutral-100"],
        backgroundColor: theme["neutral-10"],
        border: `1px solid ${theme["neutral-20"]}`,
        borderRadius: 6,
        position: "relative",
        "&:hover": {
            background: "rgba(37, 37, 39, 0.5)",
            "& .download": {
                display: "inline-flex"
            }
        },
        "& p": [
            {
                lineHeight: "22px",
                fontSize: 14,
                width: 164,
            },
            textEllipsis1
        ],
        "& .download": {
            height: 34,
            width: 34,
            display: "none",
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme["neutral-0"],
            borderRadius: 6
        }
    }
});
const download = (file) => {
    S3.getObjectURL(file.path)
        .then((objectUrl) => {
        const anchor = document.createElement("a");
        anchor.href = objectUrl;
        anchor.download = file.name;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(objectUrl);
    });
};
export const FileList = ({ files, placeholder }) => {
    const permissions = usePermissions(["VIEW_ATTACHMENTS"]);
    if (files.length === 0)
        return (_jsx(Input, { placeholder: placeholder }));
    return (_jsx("ul", { className: style, children: files.map(file => _jsx("li", { children: _jsxs("div", { className: "file-wrapper", onClick: () => permissions.VIEW_ATTACHMENTS && download(file), children: [_jsx(SvgIcon, { name: "ic_files", className: "mt-1" }), _jsxs("div", { className: "ml-3", children: [_jsx("p", { className: "my-0", children: file.name }), _jsx("p", { className: "my-0", style: { color: theme["neutral-50"] }, children: formatBytes(file.size) })] }), permissions.VIEW_ATTACHMENTS &&
                        _jsx("div", { className: "download", children: _jsx(SvgIcon, { name: "ic_download", size: 22 }) })] }) }, file.id)) }));
};
