import { Auth } from "aws-amplify";
import axios from "axios";
const client = axios.create({
    paramsSerializer: { indexes: null }
});
client.interceptors.request.use(config => Auth.currentSession().then((session) => {
    config.headers.Authorization = "Bearer " + session.getAccessToken().getJwtToken();
    return Promise.resolve(config);
}), e => Promise.reject(e));
export function getObjectURL(url) {
    return client.get(url, { responseType: "blob" })
        .then(res => window.URL.createObjectURL(res.data));
}
