import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { css } from "@emotion/css";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import config, { theme } from "~/config";
const style = css({
    height: "100vh",
    "& .header-img": {
        marginBottom: 30,
        height: 118,
        width: 92
    },
    "& [data-amplify-authenticator]": {
        height: "100%",
        flex: 1,
        "& .amplify-label": {
            fontWeight: 600
        },
        "& .amplify-button[data-variation='link']": {
            textDecoration: "underline"
        },
        "& .amplify-input:hover": {
            borderColor: theme["primary-80"],
        },
        "& .amplify-button.amplify-field__show-password:hover": {
            backgroundColor: "initial",
            borderInlineStartColor: theme["primary-80"],
        },
    },
    "& [data-amplify-authenticator-signin]": {
        "& > div": {
            rowGap: 30,
            "& > button": {
                marginTop: 30
            }
        },
        "& fieldset": {
            gap: 30,
        },
        "& .amplify-alert .amplify-alert__body": {
            fontSize: 15
        }
    },
    "& [data-amplify-footer]": {
        paddingTop: 16
    },
    "& [data-amplify-authenticator-resetpassword]": {
        "& fieldset": {
            gap: 30,
            "& > div:last-child": {
                marginTop: 30
            }
        },
        "& .amplify-alert .amplify-alert__body": {
            fontSize: 15
        }
    },
    "& [data-amplify-authenticator-confirmresetpassword]": {
        "& fieldset": {
            rowGap: 60,
            "& > div:first-child": {
                rowGap: 30
            }
        },
        "& .amplify-alert .amplify-alert__body": {
            fontSize: 15
        }
    },
    "& .bg-image": {
        height: "100%",
        flex: 1,
        backgroundImage: "url('/img/login_illustration.svg')",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    }
});
const components = {
    Header: () => (_jsx("img", { src: config.logo.whiteBg, className: "header-img" })),
    ResetPassword: {
        Header: () => (_jsx(_Fragment, {}))
    },
    ConfirmResetPassword: {
        Header: () => (_jsx(_Fragment, {}))
    }
};
const formFields = {
    signIn: {
        username: { isRequired: true }
    },
    forceNewPassword: {
        password: { isRequired: true },
        confirm_password: { isRequired: true }
    },
    resetPassword: {
        username: { isRequired: true }
    },
    confirmResetPassword: {
        confirmation_code: { isRequired: true },
        password: { isRequired: true },
        confirm_password: { isRequired: true }
    }
};
export const AuthLayout = () => {
    const { route } = useAuthenticator(context => [context.route]);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    useEffect(() => {
        if (route === "authenticated")
            navigate(from, { replace: true });
    }, [from, navigate, route]);
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: "VeR" }) }), _jsxs(Row, { className: style, children: [_jsx(Col, { span: 12, children: _jsx(Authenticator, { hideSignUp: true, loginMechanisms: ["email"], components: components, formFields: formFields }) }), _jsx(Col, { span: 12, children: _jsx("div", { className: "bg-image" }) })] })] }));
};
