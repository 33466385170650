import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRequest } from "ahooks";
import { Badge, Popover } from "antd";
import { createStyles } from "antd-style";
import { useMemo } from "react";
import { theme } from "~/config";
import { useEvents } from "~/contexts";
import { SvgIcon } from "~/icons";
import { ProjectManagementService } from "~/services";
import { Button, customScroll } from "~/styles";
import { EmptyContent } from "../empty-content";
import { Loading } from "../loading";
import { NotificationItem } from "./notification-item";
const useStyles = createStyles(({ css }, props) => {
    return css({
        ".ant-popover-inner": {
            padding: 0,
            width: 380,
            ".ant-popover-title": {
                padding: 20,
                margin: 0,
                borderBottom: `1px solid ${theme["neutral-20"]}`
            },
            ".ant-popover-inner-content": {
                padding: props.isEmpty ? "16px 20px" : 0,
                height: 418,
                ...customScroll,
            },
            "ul": {
                listStyleType: "none",
                margin: 0,
                padding: 0,
            },
            ".ant-empty-description": {
                fontSize: 14,
                "p": {
                    margin: "0 0 8px 0"
                },
                "p:first-child": {
                    color: theme["neutral-100"]
                }
            }
        }
    });
});
export const NotificationsPopover = () => {
    const getNotifications = useRequest(ProjectManagementService.getNotifications);
    const getCounter = useRequest(ProjectManagementService.getNotificationsCounter);
    const markAsRead = useRequest(ProjectManagementService.markNotificationAsRead, { manual: true });
    const archiveNotifications = useRequest(ProjectManagementService.archive, { manual: true });
    const { eventEmitter } = useEvents();
    eventEmitter.NOTIFICATION.useSubscription(() => {
        getNotifications.run();
        getCounter.run();
    });
    const content = useMemo(() => {
        if (getNotifications.loading)
            return (_jsx(Loading, {}));
        if (getNotifications.data) {
            if (getNotifications.data.length === 0) {
                return (_jsx(EmptyContent, { image: _jsx(SvgIcon, { name: "notification_ilustration", size: 62 }), description: (_jsxs(_Fragment, { children: [_jsx("p", { children: _jsx("b", { children: "Sem notifica\u00E7\u00F5es a apresentar" }) }), _jsx("p", { children: "Novidades relacionadas com projetos, visitas e defini\u00E7\u00F5es ser\u00E3o apresentadas aqui." })] })) }));
            }
            else {
                return (_jsx("ul", { children: getNotifications.data.map(e => _jsx(NotificationItem, { notification: e }, e.id)) }));
            }
        }
    }, [getNotifications]);
    const onMarkAsRead = () => {
        markAsRead.runAsync().then(() => {
            getNotifications.run();
            getCounter.run();
        });
    };
    const onArchiveNotifications = () => {
        archiveNotifications.runAsync().then(() => getNotifications.run());
    };
    const { styles } = useStyles({ isEmpty: getNotifications.data?.length === 0 });
    const popoverProps = {
        content,
        overlayClassName: styles,
        title: (_jsxs("div", { className: "d-flex justify-space-between align-center", children: [_jsx("div", { children: "Notifica\u00E7\u00F5es" }), getNotifications.data?.length > 0 && !getNotifications.loading &&
                    (getNotifications.data?.map(n => n.read).every(e => e === true) ?
                        _jsx(Button, { style: { fontSize: 12, padding: 0, height: 24 }, type: "link", onClick: onArchiveNotifications, children: "Limpar todas" }) :
                        _jsx(Button, { style: { fontSize: 12, padding: 0, height: 24 }, type: "link", onClick: onMarkAsRead, children: "Marcar todas como lidas" }))] }))
    };
    return (_jsx(Popover, { ...popoverProps, placement: "bottomRight", trigger: "click", arrow: false, children: _jsx(Badge, { count: getCounter.data?.unread, offset: [-5, 7], size: "small", children: _jsx(Button, { type: "text", shape: "circle", icon: _jsx(SvgIcon, { name: "ic_notifications", size: 24 }) }) }) }));
};
