import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { create as createModal, useModal } from "@ebay/nice-modal-react";
import { Modal, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { InfoTooltipContext, Loading, ProjectCard } from "~/components";
import { ProjectManagementService } from "~/services";
import { Button } from "~/styles";
import { defaultModalProps } from "~/utils";
import style from "./style";
export const ProjectCreatedModal = createModal(({ projectId }) => {
    const modal = useModal();
    const defaultProps = defaultModalProps(modal);
    const [project, setProject] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const tooltipContext = useContext(InfoTooltipContext);
    const navigate = useNavigate();
    const onOpenClick = () => {
        defaultProps.onOk();
        tooltipContext.showTooltip({ key: "project_created", text: "Adicione aqui o primeiro ficheiro GEO ao projeto" });
        navigate(`/projects/${projectId}`);
    };
    const onGoToProjectsClick = () => {
        defaultProps.onOk();
    };
    useEffect(() => {
        setLoading(true);
        ProjectManagementService.getProject(projectId)
            .then(setProject)
            .finally(() => setLoading(false));
    }, [projectId]);
    const modalProps = {
        ...defaultProps,
        title: "Projeto criado com sucesso",
        footer: (_jsxs(Space, { size: 24, className: "full-width", children: [_jsx(Button, { onClick: onGoToProjectsClick, children: "Ver em Projetos" }), _jsx(Button, { type: "primary", onClick: onOpenClick, children: "Abrir" })] }))
    };
    return (_jsx(Modal, { ...modalProps, className: style, width: 484, children: loading ? _jsx(Loading, {}) : (!project ? "Error" : _jsx(ProjectCard, { project: project })) }));
});
