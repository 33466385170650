import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { css } from "@emotion/css";
import { Avatar } from "antd";
import { theme } from "~/config";
import { textEllipsis } from "~/styles";
const style = ({ avatarSize }) => css({
    display: "flex",
    alignItems: "center",
    "& .avatar-text": {
        marginLeft: 12,
        width: `calc(100% - 12px - ${avatarSize}px)`,
        "& p": {
            maxWidth: "100%",
            ...textEllipsis(2)
        }
    }
});
export const TripAvatar = ({ trip, avatarSize = 40 }) => {
    return (_jsxs("div", { className: style({ avatarSize }) + " trip-avatar", children: [_jsxs(Avatar, { className: "trip-avatar", shape: "square", size: avatarSize, style: { backgroundColor: theme["neutral-20"], color: theme["neutral-50"] }, children: ["#", trip.sequence_id] }), _jsx("div", { className: 'avatar-text', children: _jsx("h3", { className: "my-0", children: trip.name }) })] }));
};
