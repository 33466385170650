import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useModal } from "@ebay/nice-modal-react";
import { cx } from "@emotion/css";
import { App, Dropdown, Table } from "antd";
import { format } from "date-fns";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { EmptyContent, InfoTooltip, InfoTooltipContext, Loading, StatusTag, UserAvatarGroup } from "~/components";
import { SvgIcon } from "~/icons";
import { ProjectManagementService } from "~/services";
import { Button, idCollumnStyle, textEllipsis1 } from "~/styles";
import { globalNotificationConfig, useErrorBoundary, usePermissions } from "~/utils";
const actions = (record, { onEditClick, onDeleteClick }) => ([
    { key: "edit", label: "Editar", icon: _jsx(SvgIcon, { name: "ic_edit" }), onClick: () => onEditClick(record) },
    { key: "delete", label: "Apagar", icon: _jsx(SvgIcon, { name: "ic_bin" }), danger: true, onClick: () => onDeleteClick(record.id) }
]);
const columns = ({ onDetailClick, onEditClick, onDeleteClick, permissions }) => {
    const cols = [
        {
            title: "ID",
            dataIndex: "sequence_id",
            defaultSortOrder: "descend",
            sorter: (a, b) => a.sequence_id - b.sequence_id,
            render: (v) => _jsxs("span", { className: cx(idCollumnStyle, "type-badge-1"), children: ["#", v] })
        },
        {
            title: "Nome",
            dataIndex: "name",
            width: "40%",
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (text, record) => (_jsxs(_Fragment, { children: [_jsx("p", { className: "mt-0 mb-0", children: _jsx("b", { children: text }) }), _jsx("p", { className: `mt-0 mb-0 ${textEllipsis1}`, children: record.description }), _jsx("a", { className: "type-body-6", onClick: () => onDetailClick(record), children: "Ver detalhes" })] }))
        },
        {
            title: "Estado",
            dataIndex: "status",
            sorter: (a, b) => a.status - b.status,
            render: v => _jsx(StatusTag.Trip, { status: v })
        },
        {
            title: "Membros",
            dataIndex: "members",
            render: (members) => _jsx(UserAvatarGroup, { users: members })
        },
        {
            title: "Data",
            dataIndex: "trip_date",
            sorter: (a, b) => a.trip_date.getTime() - b.trip_date.getTime(),
            render: text => format(text, "dd MMM yyyy")
        },
        {
            title: (_jsx(InfoTooltip, { tooltipKey: "deleted_trip_point", children: "Pontos de visita" })),
            dataIndex: "points",
            align: "center",
            render: text => text.length || 0
        },
        {
            key: "actions",
            render: record => permissions.UPDATE_OR_DELETE_TRIP && (_jsx(Dropdown, { trigger: ['click'], menu: { items: actions(record, { onEditClick, onDeleteClick }) }, children: _jsx(Button, { type: "text", shape: "circle", icon: _jsx(SvgIcon, { name: "ic_options", size: 20 }) }) }))
        }
    ];
    if (permissions.VIEW_ATTACHMENTS)
        cols.splice(3, 0, {
            title: "Anexos",
            dataIndex: "files_count",
            sorter: (a, b) => a.files_count - b.files_count,
            render: v => (_jsxs("div", { className: "d-flex align-center", children: [_jsx(SvgIcon, { name: "ic_attachment", size: 22 }), _jsx("span", { className: "ml-2", children: v || 0 })] })),
        });
    return cols;
};
export const TripsTable = ({ project, getProjectData, tabActionRef }) => {
    const tripEditModal = useModal('trip-edit-modal');
    const deleteModal = useModal("confirmation-modal");
    const tripDetailModal = useModal('trip-detail-modal');
    const tooltipContext = useContext(InfoTooltipContext);
    const permissions = usePermissions(["CREATE_TRIP", "UPDATE_OR_DELETE_TRIP", "VIEW_ATTACHMENTS"], { project });
    const showBoundary = useErrorBoundary();
    const { notification } = App.useApp();
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const getData = useCallback(() => {
        setLoading(true);
        ProjectManagementService.getTrips({ project_id: project.id })
            .then(setData)
            .catch(showBoundary)
            .finally(() => setLoading(false));
    }, [project.id]);
    const onDeleteClick = useCallback((tripId) => {
        deleteModal.show({ sentence: "Tem a certeza que quer apagar esta visita de campo?" })
            .then(() => ProjectManagementService.deleteTrip(tripId))
            .then(() => notification.success({ message: 'Visita de campo apagada', ...globalNotificationConfig }))
            .then(getData).then(getProjectData)
            .catch(() => notification.error({ message: 'Erro ao apagar a visita de campo', ...globalNotificationConfig }));
    }, [deleteModal, getData]);
    const onEditClick = useCallback((trip) => {
        tripEditModal.show({ projectId: project.id, projectStatus: project.status, trip })
            .then(getData).then(getProjectData);
    }, [getData, getProjectData, project.id, project.status, tripEditModal]);
    const onDetailClick = useCallback(trip => tripDetailModal.show({ tripId: trip.id }), [tripDetailModal]);
    const columnsMemo = useMemo(() => columns({ onDetailClick, onEditClick, onDeleteClick, permissions }), [onDeleteClick, onDetailClick, onEditClick]);
    useEffect(getData, [getData]);
    const onAddClick = useCallback(() => tripEditModal.show({ projectId: project.id, projectStatus: project.status })
        .then(getData).then(getProjectData)
        .then(() => tooltipContext.showTooltip({ key: "trip_created", text: "Adicione aqui anexos às suas visitas de campo" }))
        .catch(showBoundary), [getData, getProjectData, project.id, project.status, showBoundary, tooltipContext, tripEditModal]);
    if (loading)
        return _jsx(Loading, {});
    return (_jsxs(_Fragment, { children: [tabActionRef.current && permissions.CREATE_TRIP && createPortal(data.length > 0 && (_jsx(Button, { icon: _jsx(SvgIcon, { name: "ic_plus", size: 17 }), type: "primary", onClick: onAddClick, children: "Adicionar" })), tabActionRef.current), data.length === 0 ? (_jsx(EmptyContent, { button: permissions.CREATE_TRIP ? { content: "Criar visita", onClick: onAddClick } : undefined, className: "py-16 mb-6", description: "Aqui pode consultar visitas de campo do projeto" })) : (_jsx(Table, { dataSource: data, columns: columnsMemo, rowKey: "id", showSorterTooltip: false, sortDirections: ["ascend", "descend", "ascend"], pagination: { hideOnSinglePage: true } }))] }));
};
